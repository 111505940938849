import React, { useState, useEffect } from "react";
import {
  Popover,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  CREATE_TICKET,
  URGENT_ORDER,
  ALL_USERS,
  ORDER_NOT_UPDATE,
  CANCEL_ORDER,
  TWILIO_SMS_LIST_UNREAD,
  ORDER_MISSED_CALLS,
  NOTIFICATION_LIST
} from "../../../utils/apiUrl";
import { getApiWithAuth, putApiWithAuth } from "../../../utils/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { postApiWithAuth } from "../../../utils/api";
import { UPDATE_PROFILE_PIC } from "../../../utils/apiUrl";
import { CheckBox } from "@mui/icons-material";


// Tickets Popup
export const TicketsPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [ticketsData, setTicketsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const pid = localStorage.getItem("pharmacyId");
  const userType = localStorage.getItem("userType");
  const handleTicketsClear = () => {
    setTicketsData([]);
  };
  const getTicketOrders = async () => {
    setLoading(true);
    const response = await getApiWithAuth(CREATE_TICKET, { pending: true });
    if (response.data !== undefined) {
      setTicketsData(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketOrders();
  }, []);
  useEffect(() => {
    if (onCountUpdate) {
      const pendingTickets = ticketsData?.filter(
        (order) => order.ticket_status === "Pending"
      );
      const totalPendingTickets = pendingTickets?.length;
      onCountUpdate(totalPendingTickets);
    }
  }, [ticketsData, onCountUpdate]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "400px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Tickets
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {ticketsData.length > 0 ? (
            ticketsData?.slice(0, 3).map((ticket, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID {ticket.order.slug}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client: {ticket.order.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600, color: "#ff0101" }}
                  >
                    {ticket.ticket_status}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    by: {ticket.created_by.first_name}{" "}
                    {ticket.created_by.last_name}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(ticket.created_at).format("MM-DD-YYYY")} |{" "}
                    {moment(ticket.created_at).format("hh:mm:ss a")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No tickets available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "80px", height: "30px", fontSize: "11px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleTicketsClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "11px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See&nbsp;All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
// Tickets Popup
export const MessagesPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [tickets, setTickets] = useState([]);
  const [totalUnreadCount, setTotalUnreadCount] = useState(null);
  const pid = localStorage.getItem("pharmacyId");
  const userType = localStorage.getItem("userType");
  const [loading, setLoading] = useState(false);
  const handleTicketsClear = () => {
    setTickets([]);
  };

  const getTicketOrders = async () => {
    setLoading(true);
    const response = await getApiWithAuth(TWILIO_SMS_LIST_UNREAD);
    if (response.data !== undefined) {
      setTickets(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initialize WebSocket connection
    const unreadSMSClient = new W3CWebSocket(
      "wss://testadmin.pilldrop.ai/ws/unread_sms/"
    );
    // Handle connection open
    unreadSMSClient.onopen = () => {
      console.log("WebSocket connection established for unread SMS counts");
    };

    // Handle incoming messages
    unreadSMSClient.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === "unread_sms_counts") {
          const counts = data.data;
          const { total_unread_count, unread_counts } = counts;
          setTotalUnreadCount(total_unread_count);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };
    unreadSMSClient.onclose = () => {
      console.log("WebSocket connection closed for unread SMS counts");
    };
    unreadSMSClient.onerror = (error) => {
      console.error("WebSocket error for unread SMS counts:", error);
    };
    return () => {
      unreadSMSClient.close();
    };
  }, []);

  useEffect(() => {
    getTicketOrders();
  }, []);

  useEffect(() => {
    if (onCountUpdate) {
      const count =
        totalUnreadCount === null ? tickets?.length : totalUnreadCount;
      onCountUpdate(count);
    }
  }, [tickets, totalUnreadCount, onCountUpdate]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "400px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Client SMS
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {tickets.length > 0 ? (
            tickets?.slice(0, 3).map((ticket, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID{" "}
                    {ticket?.order?.slug ? ticket?.order?.slug : "Nill"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client:{" "}
                    {ticket?.order?.name ? ticket?.order?.name : "Unknown"}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600, color: "#ff0101" }}
                  >
                    {ticket?.ticket_status}
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {/* by: {ticket?.created_by?.first_name} {ticket?.created_by?.last_name} */}
                    Client Number {ticket.clientNumber}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(ticket?.created_at).format("MM-DD-YYYY")} |{" "}
                    {moment(ticket?.created_at).format("hh:mm:ss a")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No sms available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleTicketsClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
export const CallsPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [missedCalls, setMissedCalls] = useState([]);
  const [totalUnreadCount, setTotalUnreadCount] = useState(null);
  const pid = localStorage.getItem("pharmacyId");
  const userType = localStorage.getItem("userType");
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const handleTicketsClear = () => {
    setMissedCalls([]);
  };

  const getTicketOrders = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ORDER_MISSED_CALLS, {
      missed: "true",
      navData: "true"
    });
    if (response.data !== undefined) {
      setMissedCalls(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketOrders();
  }, []);
  const makeCall = (call) => {
    setSelectedOrder({
      number: call.clientNumber,
      id: call?.order?.id,
      name: call?.order?.name,
    });
  };

  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(missedCalls?.length);
    }
  }, [missedCalls]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "400px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Missed Calls
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {missedCalls.length > 0 ? (
            missedCalls?.slice(0, 3).map((calls, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID {calls?.order?.slug ? calls?.order?.slug : "Nill"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client:{" "}
                    {calls?.order?.name ? calls?.order?.name : "Unknown"}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600, color: "#ff0101" }}
                  >
                    {ticket?.ticket_status}
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {/* by: {ticket?.created_by?.first_name} {ticket?.created_by?.last_name} */}
                    from : {calls.clientNumber}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(calls?.created_at).format("MM-DD-YYYY")} |{" "}
                    {moment(calls?.created_at).format("hh:mm:ss a")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No calls available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleTicketsClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
// Urgent Orders Popup
export const UrgentOrdersPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [urgentOrders, setUrgentOrders] = useState([]);
  const handleOrdersClear = () => {
    setUrgentOrders([]);
  };
  const userType = localStorage.getItem("userType");

  const getOrders = async () => {
    const response = await getApiWithAuth(URGENT_ORDER);
    if (response.data !== undefined) {
      const allOrders = response.data.data.data;
      setUrgentOrders(allOrders);
    }
  };
  useEffect(() => {
    getOrders();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(urgentOrders?.length || 0); // Pass the count to the parent
    }
  }, [urgentOrders, onCountUpdate]);
  const handleClick = () => {
    localStorage.setItem("urgentOrder", true);
    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
      navigate(`/order-list?${true}`);
    } else {
      navigate(`/order-list?${true}`);
    }
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "350px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Urgent Orders
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {urgentOrders.length > 0 ? (
            urgentOrders?.slice(0, 3).map((order, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID {order.slug}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client: {order.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Pharmacy Name: {order.pharmacy_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", color: "#fe9603", fontWeight: 600 }}
                  >
                    {moment(order.createdAt).format("MM-DD-YYYY")} |{" "}
                    {moment(order.createdAt).format("HH:mm:ss")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No orders available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleOrdersClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={handleClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export const NotificationsPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [notifications, setNotifications] = useState([]);
  const fetchNotifications = async () => {
    // const validEndDate =
    //   dateRange[1] && !isNaN(dateRange[1].getTime())
    //     ? dateRange[1]
    //     : new Date();
    try {
      const response = await getApiWithAuth(NOTIFICATION_LIST);
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching facility data:", error);
    }
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(notifications?.length || 0);
    }
  }, [notifications, onCountUpdate]);

  const handleOrdersClear = () => {
    setNotifications([]);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "350px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Announcement
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {notifications?.length > 0 ? (
            notifications?.slice(0, 3)?.map((notification, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  {truncateText(notification?.heading, 25)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "11px",
                    color: "#555",
                    mb: 1,
                  }}
                >
                  {truncateText(notification?.description, 50)}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "11px",
                      color: "#555",
                      fontWeight: 500,
                    }}
                  >
                    {moment(notification?.create_date).format("MM-DD-YYYY")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "11px",
                      color: "#555",
                      fontWeight: 500,
                    }}
                  >
                    {moment(notification?.end_date).format("MM-DD-YYYY")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No announcement available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleOrdersClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

// New Update Popup
export const NewUpdatePopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [updateData, setUpdateData] = useState([]);
  const handleUpdateClear = () => {
    setUpdateData([]);
  };
  const getNotes = async () => {
    try {
      const response1 = await getApiWithAuth(ORDER_NOT_UPDATE);
      const allOrders = response1.data.data.data;
      setUpdateData(response1.data.data.data);
      const UID = localStorage.getItem("UID");
      const updatedPersonalDetail = new FormData();
      updatedPersonalDetail.append("user_id", UID);
      updatedPersonalDetail.append("action", "last_view_admin_notes");
      // const responsePersonal = await putApiWithAuth(
      //   ALL_USERS + `all`,
      //   updatedPersonalDetail
      // );
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);
  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(updateData?.length || 0); // Pass the count to the parent
    }
  }, [updateData, onCountUpdate]);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "400px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          New Update
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {updateData.length > 0 ? (
            updateData?.slice(0, 3).map((update, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    {update.slug}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client: {update.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600, color: "#ff0101" }}
                  >
                    Update From Facility
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(update.notecreatedAt).format("MM-DD-YYYY")} |{" "}
                    {moment(update.notecreatedAt).format("hh:mm:ss A")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No updates available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleUpdateClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

// Cancel Orders Popup
export const CancelOrdersPopup = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [cancelOrders, setCancelOrder] = useState([]);
  const handleCancelOrdersClear = () => {
    setCancelOrder([]);
  };
  const getOrders = async () => {
    try {
      const response = await getApiWithAuth(CANCEL_ORDER);
      if (response?.data?.data) {
        setCancelOrder(response.data.data.data);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(cancelOrders?.length || 0); // Pass the count to the parent
    }
  }, [cancelOrders, onCountUpdate]);

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "450px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Canceled Orders
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {cancelOrders.length > 0 ? (
            cancelOrders?.slice(0, 3).map((cancelOrder, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,

                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID {cancelOrder.slug}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client: {cancelOrder.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600, color: "#ff0101" }}
                  >
                    Canceled
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px" }}
                  ></Typography>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(cancelOrder.cancelAt).format("MM-DD-YYYY")} |{" "}
                    {moment(cancelOrder.cancelAt).format("hh:mm:ss A")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No Data available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleCancelOrdersClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

//Change User Modal

export const AvatarModal = ({
  open,
  handleClose,
  onColorSelect,
  displayMode,
  toggleDisplayMode,
}) => {
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [selectedProfilePic, setSelectedProfilePic] = useState();
  const [selectedColor, setSelectedColor] = useState("Default Blue");
  const colorOptions = [
    {
      label: "Default Blue",
      backgroundColor: "#0B6699",
      hoverColor: "#044367",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Light Gray",
      backgroundColor: "#E6E6E6",
      hoverColor: "#C7C6C6",
      fontColor: "#000000",
      iconColor: "#000",
    },
    {
      label: "Dark Blue",
      backgroundColor: "#091235",
      hoverColor: "#4A5275",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Dark Gray",
      backgroundColor: "#2B4257",
      hoverColor: "#19334C",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Greenish Gray",
      backgroundColor: "#53655C",
      hoverColor: "#415E4F",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Deep Purple",
      backgroundColor: "#341f31",
      hoverColor: "#492D45",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
  ];
  const profilePic = localStorage.getItem("profilePic");

  useEffect(() => {
    setSelectedProfilePic(profilePic);
  }, [profilePic]);

  const handleAvatarUpload = async (e = null, color) => {
    console.log("e and color", e, color)
    // Handle file upload if a file is provided
    const file = e?.target?.files?.[0]; // Safely check for the file
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setAvatarPreview(previewUrl);
  
      const formData = new FormData();
      formData.append("profile_pic", file);
  
      try {
        const response = await postApiWithAuth(UPDATE_PROFILE_PIC, formData);
        if (response.data) {
          const newProfilePicUrl = response.data.data.user.profile_pic;
          setSelectedProfilePic(newProfilePicUrl);
          localStorage.setItem("profilePic", newProfilePicUrl);
          console.log("Profile picture updated successfully");
        } else {
          console.log("Failed to update profile picture:", response);
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
      }
    } else {
      console.log("No file selected for upload, skipping file upload.");
    }
  
    // Handle color updates
    if (color) {
      const colorFormData = new FormData();
      colorFormData.append("selected_color", color);
  
      try {
        const colorResponse = await postApiWithAuth(UPDATE_PROFILE_PIC, colorFormData);
        if (colorResponse.data) {
          console.log("Profile updated successfully");
        } else {
          console.log("Failed to update profile:", colorResponse);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    }
  };
  

  const handleColorSelect = (option) => {
    setSelectedColor(option.label); // Update the selected color
    handleAvatarUpload(null,option.label);
    onColorSelect(
      option.backgroundColor,
      option.fontColor,
      option.iconColor,
      option.hoverColor
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          position: "absolute",
          top: 64,
          right: 40,
          margin: 0,
          width: "300px",
        },
      }}
    >
      <DialogContent>
        {/* Avatar Display Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: 3,
          }}
        >
          <img
            src={selectedProfilePic || "/icons/person.svg"}
            alt="Current Avatar"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "8px",
            }}
          />
          <Button variant="outlined" component="label">
            Edit Avatar
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleAvatarUpload}
            />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {colorOptions.map((option) => (
              <Box
                key={option.label}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedColor === option.label}
                      onChange={() => handleColorSelect(option)}
                      color="primary"
                      sx={{ width: "0px", height: "0px" }}
                    />
                  }
                  sx={{
                    marginLeft: 1,
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: option.backgroundColor,
                    width: "40px",
                    height: "20px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginRight: "10px",
                  }}
                ></Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Display Mode Toggle */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            paddingX: 1,
            marginTop: 3,
          }}
        >
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            Small
          </Typography>
          <Switch
            checked={displayMode === "large"}
            onChange={toggleDisplayMode}
            color="primary"
          />
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            Large
          </Typography>
        </Box> */}
      </DialogContent>
    </Dialog>
  );
};

export const RescheduleModal = ({
  open,
  anchorEl,
  handleClose,
  onSeeAllClick,
  onCountUpdate,
}) => {
  const [ticketsData, setTicketsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const pid = localStorage.getItem("pharmacyId");
  const userType = localStorage.getItem("userType");
  const handleTicketsClear = () => {
    setTicketsData([]);
  };
  const getTicketOrders = async () => {
    setLoading(true);
    const response = await getApiWithAuth(CREATE_TICKET, { pending: true });
    if (response.data !== undefined) {
      setTicketsData(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketOrders();
  }, []);
  useEffect(() => {
    if (onCountUpdate) {
      const pendingTickets = ticketsData?.filter(
        (order) => order.ticket_status === "Pending"
      );
      const totalPendingTickets = pendingTickets?.length;
      onCountUpdate(totalPendingTickets);
    }
  }, [ticketsData, onCountUpdate]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#e2e2e2",
          width: "400px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", fontWeight: 600, mb: 1, color: "#ff0101" }}
        >
          Reschedule Orders
        </Typography>
        <Box sx={{ minHeight: "50px", maxHeight: "300px", overflowY: "auto" }}>
          {/* Map through the ticketsData array to render each ticket */}
          {ticketsData.length > 0 ? (
            ticketsData?.slice(0, 3).map((ticket, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  backgroundColor: "#fff",
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Order ID {ticket.order.slug}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    Client: {ticket.order.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    Address: {ticket.order.address || "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    {moment(ticket.created_at).format("MM-DD-YYYY")} |{" "}
                    {moment(ticket.created_at).format("hh:mm:ss a")}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", textAlign: "center", my: 2 }}
            >
              No reschedule requests available.
            </Typography>
          )}
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              width: "72px", height: "30px", fontSize: "12px",
              borderRadius: "8px",
              backgroundColor: "#0b6699",
              textTransform: "none",
            }}
            onClick={handleTicketsClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "80px", height: "30px", fontSize: "12px",
              ml: 2,
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={onSeeAllClick}
          >
            See All
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

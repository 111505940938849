import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField, Modal } from "@mui/material";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../../../buttonCompo/Buttons";
import moment from "moment";
import HistoryComponent from "../../popups/HistoryComponent";
import { HistoryIcon } from "../../../Icons";
import {
  CREATE_TICKET,
  CREATE_COMMENT,
  GET_HISTORY,
} from "../../../../utils/apiUrl";
import { getApiWithAuth, postApiWithAuth } from "../../../../utils/api";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getStatusMessage(
  statusCode,
  orderPlaceRoute4me,
  orderFailed,
  isOutforDelivery,
  userType
) {
  switch (statusCode) {
    case "1":
      return "Ready to Pick Up";
    case "2":
      return "Picked Up";
    case "3":
      return "Received At Facility";
    case "4":
      if (orderPlaceRoute4me && orderFailed && !isOutforDelivery) {
        return "Failed";
      } else if (isOutforDelivery) {
        return "Out for Delivery";
      } else if (!orderPlaceRoute4me && !orderFailed && !isOutforDelivery) {
        return "Dispatching";
      } else if (orderPlaceRoute4me && !orderFailed && !isOutforDelivery) {
        return "Route Created";
      }
      return "Unknown Status";
    case "5":
      return "Delivered";
    case "6":
      return "Cancelled";
    case "7":
      return (userType === "PharmacyOwner" ||
        userType === "PharmacyEmployee") ? "Cancelled" : "Return Created";
    case "8":
      return (userType === "PharmacyOwner" ||
        userType === "PharmacyEmployee") ? "Cancelled" : "Return Route Created";
    case "9":
      return (userType === "PharmacyOwner" ||
        userType === "PharmacyEmployee") ? "Cancelled" : "Returned to Pharmacy";
    default:
      return "Unknown Status";
  }
}
function getStatusColor(
  statusCode,
  orderPlaceRoute4me,
  orderFailed,
  isOutforDelivery
) {
  switch (statusCode) {
    case "1":
      return "#0d4df2";
    case "2":
      return "#808080";
    case "3":
      return "#0094ff";
    case "4":
      if (orderPlaceRoute4me && orderFailed && !isOutforDelivery) {
        return "#fb1001";
      } else if (isOutforDelivery) {
        return "#ff5630";
      } else if (!orderPlaceRoute4me && !orderFailed && !isOutforDelivery) {
        return "#637381";
      } else if (orderPlaceRoute4me && !orderFailed && !isOutforDelivery) {
        return "#fe9901";
      }
      return "Unknown Status";
    case "5":
      return "#2cb04b";
    case "6":
      return "#fb1001";
    case "7":
      return "#fe9901";
    case "8":
      return "#ff5630";
    case "9":
      return "#fb1001";
    default:
      return "#000";
  }
}
const StatusInfo = ({
  showTicket,
  previousOrder = false,
  order,
  fetchorders,
}) => {

  const userType = localStorage.getItem("userType");

  const navigate = useNavigate();

  const [isTicketPopupOpen, setIsTicketPopupOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const statusMessage = getStatusMessage(
    order.status,
    order.orderPlaceRoute4me,
    order.orderFailed,
    order.isOutforDelivery,
    userType
  );
  const [orderId, setOrderId] = useState("");
  const [history, setHistory] = useState(null);
  const [comment, setComment] = useState("");
  const handleTicketClick = (id) => {
    setOrderId(id);
    setIsTicketPopupOpen(true);
  };
  const handleSaveTicket = async (e) => {
    e.preventDefault();
    console.log("Ticket Save Started"); // Log when the function starts

    setIsButtonDisabled(true);

    if (!comment) {
      console.log("No comment provided"); // Log when there's no comment
      toast.error("Please add a comment; it's mandatory!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      const ticketFormData = new FormData();
      ticketFormData.append("name", `${order.slug} Ticket`);
      ticketFormData.append("ticket_status", "Pending");
      ticketFormData.append("order", orderId);

      const responseTicket = await postApiWithAuth(
        CREATE_TICKET,
        ticketFormData
      );

      if (!responseTicket.data) {
        console.log("Ticket creation failed"); // Log if ticket creation failed
        throw new Error("Ticket creation failed");
      }

      console.log("Ticket created successfully", responseTicket.data); // Log successful ticket creation

      // Create comment
      const commentFormData = new FormData();
      commentFormData.append("comment", comment);
      commentFormData.append("ticket", responseTicket.data.data.id);

      const responseComment = await postApiWithAuth(
        CREATE_COMMENT,
        commentFormData
      );

      if (responseComment.data.status === 201) {
        // Successfully created ticket and comment
        console.log("Comment created successfully", responseComment.data); // Log successful comment creation
        setIsTicketPopupOpen(false);
        setOrderId(""); // Clear order ID
        fetchorders(); // Fetch orders again after successful ticket creation
        toast.success("Ticket Created Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log("Comment creation failed"); // Log if comment creation failed
        throw new Error("Comment creation failed");
      }
    } catch (error) {
      // General error handler
      console.error("Error occurred:", error); // Log the error message
      setIsTicketPopupOpen(false);
      setOrderId(""); // Clear order ID
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleTicketClose = () => {
    setIsTicketPopupOpen(false);
  };
  const handleCreateHistory = async () => {
    try {
      const response = await getApiWithAuth(GET_HISTORY + `${order.id}/`);
      if (response.data !== undefined) {
        setHistory(response.data.data); // Set the fetched history data
        setHistoryModalOpen(true); // Open the modal once data is fetched
      } else {
        console.log("No history data found");
      }
    } catch (error) {
      console.log("Error fetching history:", error);
    }
  };

  const handleHistoryOpen = () => {
    setHistoryModalOpen(true);
    handleCreateHistory(); // Fetch the order history
  };

  // Handle History Modal Close
  const handleHistoryClose = () => {
    setHistoryModalOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          mr: 1,
          gap: 2,
          height: "100%",
          width: previousOrder ? "auto" : "320px",
          justifyContent: showTicket ? "flex-start" : "",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            paddingLeft: !showTicket ? "25px" : 0,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
          >
            Status:
            <span
              style={{
                color: getStatusColor(
                  order.status,
                  order.orderPlaceRoute4me,
                  order.orderFailed,
                  order.isOutforDelivery
                ),
                fontSize: "12px",
              }}
            >
              {" "}
              {statusMessage}
            </span>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
          >
            Created Date:{" "}
            <span style={{ color: "#3C3C3C" }}>
              {moment(order.createdAt).format("MM-DD-YYYY")}
            </span>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
          >
            Created Time:{" "}
            <span style={{ color: "#3C3C3C" }}>
              {moment(order.createdAt).format("hh:mm:ss A")}
            </span>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
          >
            Delivery Date:{" "}
            <span style={{ color: "#3C3C3C" }}>
              {" "}
              {order.status === "5" ?
                moment(order.deliverAt).format("MM-DD-YYYY")
                :
                moment(order.deliverAtSelected).format("MM-DD-YYYY")
              }

            </span>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
          >
            Delivery Time:{" "}
            <span style={{ color: "#3C3C3C" }}>
              {order.status === "5" ?
                moment(order.deliverAt).format("hh:mm:ss A")
                :
                order.deliverydate
              }</span>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", fontWeight: 600, color: "#000" }}
            >
              Attempts:{" "}
              <span style={{ color: "#3C3C3C" }}>{order.attempt}</span>
            </Typography>
            {(userType === "PharmacyOwner" ||
              userType === "PharmacyEmployee") && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "10px",
                    height: "22px",
                    padding: 0.3,
                    marginLeft: previousOrder ? 1.2 : "",
                    textTransform: "none",
                    color: "#000",
                    borderColor: "#000",
                  }}
                  onClick={() => {
                    if (order.last_orderticket === null) {
                      handleTicketClick(order.id);
                    }
                  }}
                >
                  {order.last_orderticket === null ? (
                    "Create Ticket"
                  ) : (
                    "Ticket Created"
                  )}
                </Button>
              )}
            {previousOrder &&
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: "10px",
                  height: "22px",
                  padding: 0.3,
                  marginLeft: previousOrder ? 1.2 : "",
                  textTransform: "none",
                  color: "#000",
                  borderColor: "#000",
                }}
                onClick={() => {
                  handleHistoryOpen();
                }}
              >
                <HistoryIcon /> History
              </Button>
            }
          </Box>
        </Box>

        <Modal
          open={historyModalOpen}
          onClose={handleHistoryClose}
          aria-labelledby="history-modal"
          aria-describedby="history-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              bottom: 0, // Distance from the bottom of the screen
              right: 0,
              // transform: "translate(-50%, -50%)",
              width: 650,
              bgcolor: "background.paper",
              backgroundColor: "#f4f6f8",
              boxShadow: 24,
              overflowY: "auto",
              p: 2,
            }}
          >
            <HistoryComponent onClose={handleHistoryClose} />
          </Box>
        </Modal>

        {showTicket && (
          <Box>
            <Box
              sx={{
                height: "82%",
                width: "155px",
                borderRadius: "5px",
                border: "1px solid #DCE0E4",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => navigate(`/seealltickets/${order.last_orderticket.id}`)}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#6b6b6b",
                  paddingTop: "5px",
                }}
              >
                {order.last_orderticket.ticketcomment_set[0].comment}
              </Typography>
            </Box>
            <Typography
              sx={{
                ml: "18px",
                mt: "5px",
                fontSize: "10px",
                fontWeight: 400,
                color:
                  order.last_orderticket.ticket_status !== "Completed"
                    ? "#fe9901"
                    : "green", // Conditional color
              }}
              variant="body2"
            >
              {order.last_orderticket.ticket_status !== "Completed"
                ? `Created At: ${moment(
                  order.last_orderticket.createdAt
                ).format("MM-DD-YYYY")}`
                : `Completed At: ${moment(
                  order.last_orderticket.ticketcomment_set[
                    order.last_orderticket.ticketcomment_set.length - 1
                  ].createdAt
                ).format("MM-DD-YYYY")}`}
            </Typography>

            {/* <Box
  sx={{
    backgroundColor: "red",
    color: "white",
    fontSize: "10px",
    fontWeight: "bold",
    padding: "5px 10px",
    borderRadius: "4px",
    display: "inline-block",
    mt: "5px",
    ml: "30px",
  }}
>
  {(() => {
    if (
      userType === "PharmacyEmployee" ||
      userType === "PharmacyOwner"
    ) {
      if (order.last_orderticket.ticket_status === "Pending") {
        return "Created";
      } else if (order.last_orderticket.ticket_status === "Active") {
        return "Active";
      }
    }
    return order.last_orderticket.ticket_status; // Default text
  })()}
</Box> */}
          </Box>
        )}

        {/* Create Ticket Absolute Position Popup */}
        {isTicketPopupOpen && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: "0",
              zIndex: 10,
              width: "224px",
              backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "10px",
              border: "1px solid #DCE0E4",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "12px", color: "#0b6699" }}
            >
              Create Ticket
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              autoComplete="off"
              placeholder="Enter ticket details"
              multiline
              rows={3}
              sx={{
                mb: 2,
              }}
              name="comment"
              value={comment} // Bind the state to the TextField
              onChange={(e) => setComment(e.target.value)}
              InputProps={{
                sx: {
                  padding: 0,
                  "& .MuiInputBase-input": {
                    fontSize: "10px",
                    fontWeight: 400,
                    lineHeight: "1.2",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <OutlinedButtonWithIcon
                label="Clear"
                onClick={handleTicketClose}
                height="20px"
                width="40px"
                padding={1.3}
              ></OutlinedButtonWithIcon>
              <ContainedButton
                label="Save"
                disabled={isButtonDisabled}
                onClick={handleSaveTicket}
                height="20px"
                width="40px"
              ></ContainedButton>
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        open={historyModalOpen}
        onClose={handleHistoryClose}
        aria-labelledby="history-modal"
        aria-describedby="history-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0, // Aligns the modal at the bottom of the screen
            right: 0,
            width: 650,
            bgcolor: "background.paper",
            backgroundColor: "#f4f6f8",
            boxShadow: 24,
            overflowY: "auto",
            p: 2,
          }}
        >
          <HistoryComponent
            orderHistory={history}
            onClose={handleHistoryClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default StatusInfo;

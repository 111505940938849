import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PrintDocList from "../../components/PDF/PrintDocList";
import { DownloadIcon, EyeIcon, PrinterIcon } from "../Icons";
import { useTheme } from "@mui/material";
import JSZip from "jszip";
import moment from "moment";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PHARMACY_REPORT_COUNT } from "../../utils/apiUrl";
import { postApiWithAuth } from "../../utils/api";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { pilldropLogo } from "../../assests";
import NewPrintDocList from "../PDF/NewPrintDocList";
const columns2 = [
  { id: "date", label: "Date", minWidth: 170 },
  { id: "sameDay", label: "Same Day Orders", minWidth: 170 },
  { id: "nextDay", label: "Next Day Orders", minWidth: 170 },
  { id: "timeWindow", label: "Next Day (Time Window)", minWidth: 170 },
  { id: "urgent", label: "Static Delivery Orders", minWidth: 170 },
  { id: "fattempt", label: "1st Attempts Deliveries", minWidth: 170 },
  { id: "sattempt", label: "2nd Attempts Deliveries", minWidth: 170 },
  { id: "tattempt", label: "3rd Attempts Deliveries", minWidth: 170 },
  { id: "cancelled", label: "Cancelled Orders", minWidth: 170 },
  { id: "sum", label: "Daily Amount Sum", minWidth: 170 },
];

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: "700",
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
    border: "1px solid black",
    minWidth: "30%",
    padding: "0 10px",
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: "700",
    textAlign: "center",
  },
  header3: {
    fontSize: 18,
    marginBottom: 0,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  header4: {
    fontSize: 18,
    marginBottom: 8,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  Total: {
    fontSize: 24,
    float: "right",
    marginBottom: 8,
    marginRight: 0,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
  },
  Sums: {
    fontSize: 18,
    marginBottom: "1px",
    marginTop: "0px",
    fontWeight: "700",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 0,
    fontWeight: "700",
  },
  tableHeader: {
    // backgroundColor: '#f0f0f0',
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
  tableRow: {},
  tableCell: {
    padding: 5,
    fontSize: 12,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    fontWeight: "700",
  },
  firstColumn: {
    fontWeight: "700",
  },
  firstRow: {
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
}));
const DateOverview = ({ pharmaDetails }) => {


  const userType = localStorage.getItem("userType");

  const [invoice, setInvoice] = useState([]);
  const [signedOrders, setSignedOrders] = useState([]);
  console.log("pharmacyOrderList", pharmaDetails);
  const [loading, setLoading] = useState(null);
  const isExtraLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("lg")
  );
  const componentRef = useRef();
  const componentRef2 = useRef();
  const classes = useStyles();
  const createDate = new Date();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const totalSum = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + parseFloat(currentOrder.sum),
    0
  );
  const totalCopay = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.copay,
    0
  );
  const totalDelivered = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.deliveredOrder,
    0
  );
  const totalOrder = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.totalOrder,
    0
  );
  const totalSign = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.sign,
    0
  );
  const totalHippa = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountHippa,
    0
  );
  const totalNF = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountNF,
    0
  );
  const totalLien = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountLien,
    0
  );
  const amountCollectedCopay = invoice?.reduce(
    (accumulator, currentOrder) =>
      accumulator + currentOrder.amountCollectedCopay,
    0
  );
  const pharmacyCopay = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.pharmacyCopay,
    0
  );
  const totalCopaySlip = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.totalCopay,
    0
  );

  function formatDateToMMDDYYYY(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string if the date is undefined or empty
    }
    const parts = inputDate.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${month}/${day}/${year}`;
  }
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const days = [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" },
  ];
  const combinedData = (pharmaDetails?.dailyData || []).map(
    (dayData, index) => ({
      ...dayData,
      day: days[index]?.day || "Unknown Day",
    })
  );
  const fetchInvoice = async (dateRange) => {
    // setByDate(true);
    const [startDate, endDate] = dateRange.split(" - ");
    console.log(startDate, endDate);
    const deliveryTime = new Date(startDate);
    const deliveryTimeFrom = new Date(endDate);
    const formData = {
      from_date: startDate,
      to_date: endDate,
      pharmacy: pharmaDetails?.pharmacyDetail.id,
    };
    // const formData = { data: duration }
    const response = await postApiWithAuth(PHARMACY_REPORT_COUNT, formData);
    console.log(response);
    if (response.data !== undefined) {
      setInvoice(response.data.data);
    } else {
      console.log(response);
    }
  };
  useEffect(() => {
    if (invoice.length > 6) {
      handleDownloadPDF();
      setInvoice([]);
    }
  }, [invoice]);
  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order_File.pdf",
  });
  function getWeekNumber(date) {
    const d = new Date(date);
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7));
    const year = d.getFullYear();
    const weekNumber = Math.ceil(
      (d - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000)
    );
    return { year, weekNumber };
  }
  const handleDownloadSlip = async (fileUrls, dateRange, type) => {
    console.log("handle dwnload slips is called");
    setLoading(true);
    const [startDate, endDate] = dateRange.split(" - ");
    const weekStart = getWeekNumber(startDate);
    const dateList = [];
    if (fileUrls.length > 0) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      let current = start;
      while (current <= end) {
        const formattedDate = current.toLocaleDateString("en-GB");
        dateList.push(formattedDate);
        current.setDate(current.getDate() + 1);
      }
      const pharmacyId = pharmaDetails?.pharmacyDetail.id;
      const requestBody = {
        type: type,
        pharmacy_id: pharmacyId,
        year: weekStart.year,
        week: weekStart.weekNumber,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKENDURL}ship/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${startDate} - ${endDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else if (response.ok) {
        console.log("processing");
      } else {
        // Handle the error response
        console.error("Error:", response.statusText);
      }
    } else {
      toast.error("No files available.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setLoading(false);
  };
  async function handleDownloadImages(pharmacy, dateRange) {
    try {
      const [dateStr] = dateRange.split(" - ");
      const formatDate = (dateStr) => {
        const [month, day, year] = dateStr.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      };

      const formattedDate = formatDate(dateStr);
      const folderName = `${pharmacy}_${formattedDate}_${formattedDate}_images`;
      const handle = await window.showDirectoryPicker();
      const folderHandle = await handle.getDirectoryHandle(folderName, {
        create: true,
      });
      const fileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/all/${pharmacy}_${formattedDate}_${formattedDate}_images.zip`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const zip = await JSZip.loadAsync(blob);
      for (const fileName of Object.keys(zip.files)) {
        const file = await zip.files[fileName].async("blob");
        const fileHandle = await folderHandle.getFileHandle(fileName, {
          create: true,
        });
        const writable = await fileHandle.createWritable();
        await writable.write(file);
        await writable.close();
      }

      console.log("Files successfully saved to folder!");
    } catch (error) {
      console.error("Error during folder creation or file download:", error);
    }
  }
  const handleOpenNewTab = (pharmacy, dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(" - ");
    const startDateParts = startDateStr.split("/");
    const endDateParts = endDateStr.split("/");
    const startDate = `${startDateParts[2]}-${startDateParts[0].padStart(
      2,
      "0"
    )}-${startDateParts[1].padStart(2, "0")}`;
    const endDate = `${endDateParts[2]}-${endDateParts[0].padStart(
      2,
      "0"
    )}-${endDateParts[1].padStart(2, "0")}`;
    let realFileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/rx/${pharmacy}_${startDate}_${endDate}_rx.pdf`;
    realFileUrl = encodeURI(realFileUrl);
    realFileUrl = realFileUrl.replace(/%20/g, "+");
    console.log("real url", realFileUrl);
    window.open(realFileUrl, "_blank");
  };
  const handleOpenNewTabManual = (pharmacy, dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(" - ");
    const startDateParts = startDateStr.split("/");
    const endDateParts = endDateStr.split("/");
    const startDate = `${startDateParts[2]}-${startDateParts[0].padStart(
      2,
      "0"
    )}-${startDateParts[1].padStart(2, "0")}`;
    const endDate = `${endDateParts[2]}-${endDateParts[0].padStart(
      2,
      "0"
    )}-${endDateParts[1].padStart(2, "0")}`;
    const realFileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/manual/${pharmacy}_${startDate}_${endDate}_manual.pdf`;
    console.log("real url", realFileUrl);
    window.open(realFileUrl, "_blank");
  };
  const theme = useTheme();

  const isBeforeToday = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    return inputDate < today;
  };

  const isCurrentWeek = (dateRange) => {
    const [startDate, endDate] = dateRange.split(' - ');
    const currentDate = new Date();
    console.log("dateRange", dateRange)
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    console.log("currentDate >= startDateObj && currentDate <= endDateObj;", currentDate >= startDateObj && currentDate <= endDateObj)
    return currentDate >= startDateObj && currentDate <= endDateObj;
  };

  const isBeforeTodayCount = combinedData?.filter(dayInfo => isBeforeToday(dayInfo.date)).length || 0;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: 1,
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#f0f0f0",
            boxShadow: 1,
          },
        }}
      >
        {pharmaDetails && isExtraLargeScreen && (
          <Box sx={{ height: "80%", paddingLeft: "10px", width: "330px" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.custom.layoutBgColor,
                color: theme.palette.custom.layoutFontColor,
                borderRadius: "8px",
                padding: "6px 6px",
                minWidth: "auto",
                height: "100%",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.custom.layoutHoverColor,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "inherit" }}
                >
                  {pharmaDetails?.pharmacyDetail?.name}
                </Typography>
                <Typography
                  sx={{ fontSize: "11px", fontWeight: 400, color: "inherit" }}
                >
                  {pharmaDetails?.pharmacyDetail?.location}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingX: "30px",
            paddingY: "10px",
            height: "auto",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {/* Section Labels */}

          {!isCurrentWeek(pharmaDetails.dateRange) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                // maxWidth: "1238px",
                // backgroundColor: "red",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "11px", color: "#333" }}
                >
                  Uploaded Delivery Slips:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {pharmaDetails?.deliverySlipUnsign?.length}RX Slips
                  </span>
                </Typography>
                <PrinterIcon
                  onClick={() => {
                    console.log("PrinterIcon clicked!");
                    handleDownloadSlip(
                      pharmaDetails?.deliverySlipUnsign,
                      pharmaDetails?.dateRange,
                      "unsign"
                    );
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "11px", color: "#333" }}
                >
                  Signed Delivery Slips:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      ml: 1,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "normal",
                        fontSize: "11px",
                        color: "#333",
                      }}
                    >
                      {pharmaDetails?.deliverySlip?.length} RX Slips
                    </Typography>
                    <PrinterIcon
                      sx={{ fontSize: "16px", color: "#333", ml: 0.5 }}
                      onClick={() => {
                        handleOpenNewTab(
                          pharmaDetails?.pharmacyDetail?.name,
                          pharmaDetails?.dateRange
                        );
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      ml: 1,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "normal",
                        fontSize: "11px",
                        color: "#333",
                      }}
                    >
                      {pharmaDetails?.manual_orders_url?.length} Manual Slips
                    </Typography>
                    <PrinterIcon
                      sx={{ fontSize: "16px", color: "#333", ml: 0.5 }}
                      onClick={() => {
                        handleOpenNewTabManual(
                          pharmaDetails?.pharmacyDetail?.name,
                          pharmaDetails?.dateRange
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "11px", color: "#333" }}
                >
                  Billing & Copay Invoice:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "normal",
                      fontSize: "11px",
                      color: "#333",
                      ml: 1,
                    }}
                  >
                    Copay Collected ${pharmaDetails?.totalCopay?.toFixed(2)}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "normal",
                      fontSize: "11px",
                      color: "#333",
                      ml: 1,
                    }}
                  >
                    Delivery Charges $0.00
                  </Typography>
                </Box>
                <PrinterIcon
                  onClick={() => {
                    fetchInvoice(pharmaDetails.dateRange);
                  }}
                />
              </Box>
            </Box>

          )}

          {/* Daywise Details */}
          {(userType === "Admin" || userType === "SubAdmin") && <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: isBeforeTodayCount === 7 ? "space-between" : "flex-start",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            {combinedData?.map((dayInfo, index) => {
              const currentDate = dayInfo.date;
              if (isBeforeToday(currentDate)) {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: "8px",
                      padding: 1,
                      paddingLeft: 0,
                      gap: 1,
                    }}
                  >
                    {console.log("============>", dayInfo)}
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", fontSize: "11px", mb: 0.5 }}
                    >
                      {dayInfo.day}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        padding: "4px 8px",
                        borderRadius: "5.65px",
                        border: "1px solid #d2d5da",
                        height: "32px",
                      }}
                    >
                      <Typography sx={{ fontSize: "11px", color: "#4b5563" }}>
                        {dayInfo.date}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 0.3,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDownloadImages(
                            pharmaDetails?.pharmacyDetail?.name,
                            dayInfo.date
                          )
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            color: "#4b5563",
                            fontWeight: 700,
                          }}
                        >
                          {dayInfo.deliverySlip.length +
                            dayInfo.manual_orders_url.length}
                        </Typography>
                        <DownloadIcon />
                      </Box>
                    </Box>
                  </Box>
                )
              } else {
                console.log("no data")
              }
            })}
          </Box>}
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <div ref={componentRef} style={{ margin: 20 }}>
          <div className={classes.page}>
            <div className={classes.section}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "400" }}>
                  Date Issued: {moment(createDate).format("MM/DD/YYYY")}
                </p>
                <p style={{ fontWeight: "400" }}>Page 1/1</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "80px",
                }}
              >
                <div style={{ minWidth: "550px" }}>
                  <img
                    src={pilldropLogo}
                    alt="Mantis"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <h4 className={classes.header}>
                  Invoice{" "}
                  <span style={{ marginLeft: "10px" }}>
                    ({formatDateToMMDDYYYY(invoice[1]?.date)} To{" "}
                    {formatDateToMMDDYYYY(invoice[invoice?.length - 1]?.date)})
                  </span>
                </h4>
              </div>
              <h4 className={classes.header2}>{invoice[0]?.pharmacy?.name}</h4>
              <h4 className={classes.header3}>
                {invoice[0]?.pharmacy?.address}
              </h4>
              <h4 className={classes.header4}>
                {invoice[0]?.pharmacy?.contact}
              </h4>
              <Table className={classes.table}>
                <TableBody>
                  {columns2.map((column, i) => (
                    <TableRow key={column.id}>
                      <TableCell
                        className={`${classes.tableCell} ${classes.firstColumn}`}
                        align="center"
                      >
                        {column.label}
                      </TableCell>
                      {invoice.map((dateResult, k) => {
                        const value = dateResult[column.id];
                        return (
                          <TableCell
                            className={`${classes.tableCell} ${column.id === "date" ? classes.firstRow : ""
                              }`}
                            key={dateResult.date}
                            align="center"
                            style={{ border: "1px solid black" }}
                          >
                            {k === 0 && column.id === "date"
                              ? value
                              : column.id === "date"
                                ? formatDateToMMDDYYYY(value)
                                : column.id === "sum" && value === " "
                                  ? " "
                                  : column.id === "sum" && value !== " "
                                    ? `$ ${parseFloat(value).toFixed(2)}`
                                    : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 15px 5px 30px",
                  border: "1px solid black",
                }}
              >
                <p>Total Orders = {totalOrder}</p>
                <p>Total Amount ${parseFloat(totalSum).toFixed(2)}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "600" }}>
                  Get Forms Signed = {totalSign}
                </p>
                <p style={{ fontWeight: "600" }}>Copay Orders = {totalCopay}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid black",
                }}
              >
                <p style={{ fontWeight: "600" }}>
                  HIPAA FORM = {totalHippa}, Nf AOB FORM = {totalNF}, LIEN FORM
                  = {totalLien}
                </p>
                <div style={{ textAlign: "right" }}>
                  <p style={{ fontWeight: "600" }}>
                    Copay's Collected = ${" "}
                    {parseFloat(amountCollectedCopay).toFixed(2)}
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Copay's Pharmacy Wavied / Received = ${" "}
                    {parseFloat(pharmacyCopay).toFixed(2)}
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Total Copay = $ {parseFloat(totalCopaySlip).toFixed(2)}
                  </p>
                </div>
              </div>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "600" }}>
                  PD Receiver's Name: ______________
                </p>
                <p style={{ fontWeight: "600" }}>
                  Copay's Receiver's Name: ______________
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "600" }}>
                  PD Receiver's Signatures: ______________
                </p>
                <p style={{ fontWeight: "600" }}>
                  Copay's Receiver's Signatures: ______________
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "600" }}>
                  Form Of Payment: ______________
                </p>
                <p style={{ fontWeight: "600" }}>
                  Receiving Date: ______________
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        {signedOrders && signedOrders.length > 0 && (
          <div ref={componentRef2}>
            {signedOrders?.map((orderDetail, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  paddingTop: "10px"
                }}
              >
                <NewPrintDocList
                  orderDetail={orderDetail}
                  pharmacy={signedOrders[0]?.pharmacy}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DateOverview;

import React from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../buttonCompo/Buttons";

const DefaultRouteSetting = ({
  onClose,
  handleSaveSettings,
  formData,
  onChange,
  isListPage,
}) => {
  return (
    <Box
      sx={{
        // height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 600 }}>
          Default Route Setting
        </Typography>
      </Box>

      <div style={{ width: "300px" }}>
        {/* Optimize For */}
        <FormControl component="div" sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#707070",
              fontWeight: 500,
              marginTop: "3%",
              marginBottom: "4px",
            }}
          >
            Optimize For
          </Typography>
          <RadioGroup
            aria-label="optimizeFor"
            name="optimizeFor"
            value={formData.optimizeFor}
            onChange={(e) => onChange("optimizeFor", e.target.value)}
          >
            <FormControlLabel
              value="TIME"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Optimize by Time"
              sx={{
                marginBottom: "-6px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
            <FormControlLabel
              value="DISTANCE"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Optimize by Mile"
              sx={{
                marginTop: "-8px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
          </RadioGroup>
        </FormControl>

        {/* Route Option */}
        <FormControl component="div" sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#707070",
              fontWeight: 500,
              marginBottom: "4px",
            }}
          >
            Route Option
          </Typography>
          <RadioGroup
            aria-label="routeOption"
            name="routeOption"
            value={formData.routeOption}
            onChange={(e) => onChange("routeOption", e.target.value)}
          >
            <FormControlLabel
              value="startToEnd"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Start & End At Same Location"
              sx={{
                marginBottom: "-4px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
            <FormControlLabel
              value="endAnywhere"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="End At Any Location"
              sx={{
                marginTop: "-8px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
          </RadioGroup>
        </FormControl>

        {/* Clustering Option */}
        <FormControl component="div" sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#707070",
              fontWeight: 500,
              marginBottom: "4px",
            }}
          >
            Clustering Option
          </Typography>
          <RadioGroup
            aria-label="clusterOption"
            name="clusterOption"
            value={formData.clusterOption}
            onChange={(e) => onChange("clusterOption", e.target.value)}
          >
            <FormControlLabel
              value="none"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="None"
              sx={{
                marginBottom: "-6px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
            <FormControlLabel
              value="topologySegment"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Topology Segment"
              sx={{
                marginTop: "-8px",
                marginBottom: "-6px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
            <FormControlLabel
              value="drivingDistance"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Driving Distance"
              sx={{
                marginTop: "-8px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
          </RadioGroup>

          {formData.clusterOption === "drivingDistance" && (
            <TextField
              label="Driving Distance (meters)"
              type="number"
              autoComplete="off"
              value={formData.driving_distance_value}
              onChange={(e) =>
                onChange("driving_distance_value", e.target.value)
              }
              fullWidth
              sx={{
                marginTop: "6px",
                "& .MuiInputBase-root": {
                  fontSize: "12px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "12px",
                },
              }}
            />
          )}
        </FormControl>

        {/* Traffic Option */}
        <FormControl component="div" sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#707070",
              fontWeight: 500,
              marginBottom: "4px",
            }}
          >
            Traffic
          </Typography>
          <RadioGroup
            aria-label="traffic"
            name="traffic"
            value={formData.traffic}
            onChange={(e) => onChange("traffic", e.target.value)}
          >
            <FormControlLabel
              value="enabled"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Enable"
              sx={{
                marginBottom: "-6px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
            <FormControlLabel
              value="disable"
              control={
                <Radio
                  size="small"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#0b6699",
                    "&.Mui-checked": {
                      color: "#0b6699",
                    },
                  }}
                />
              }
              label="Disable"
              sx={{
                marginTop: "-8px",
                "& .MuiTypography-root": {
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#232323",
                },
              }}
            />
          </RadioGroup>
        </FormControl>

        {/* Extra Minutes */}
        <TextField
          label="Each Stop Extra Minutes"
          type="number"
          autoComplete="off"
          value={formData.extraMinutes}
          onChange={(e) => onChange("extraMinutes", e.target.value)}
          fullWidth
          sx={{
            marginBottom: 1,
            "& .MuiInputBase-root": {
              fontSize: "12px",
              height: "32px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "12px",
            },
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={formData.departureDateTime}
            label="Departure Date & Time"
            onChange={(e) => onChange("departureDateTime", e)}
            slotProps={{
              textField: {
                variant: "standard",
                InputProps: { disableUnderline: true },
                sx: {
                  fontSize: "12px",
                  color: "black",
                  height: "28px",
                },
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "12px",
                height: "28px",
                minHeight: "28px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <Box
        sx={{
          display: "flex",
          marginTop: "14%",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!isListPage && (
          <OutlinedButtonWithIcon
            label="Optimize"
            onClick={() => {
              onClose();
            }}
          />
        )}
        <ContainedButton
          label="Save"
          border="none"
          onClick={() => {
            handleSaveSettings();
          }}
        />
      </Box>
    </Box>
  );
};

export default DefaultRouteSetting;

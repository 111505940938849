import React, { useState, useEffect, useContext, useRef } from "react";
import { Box } from "@mui/material";
import { OrderDataContext } from "../../context/OrderDataContext";
import { useDisplayMode } from "../../context/DisplayModeContext";
import ChatWindow from "./tabButtonsCompo/ChatWindow";
import PreviousOrder from "./tabButtonsCompo/PreviousOrder";
import DateTimeFilters from "./DateTimeFilters";
import PatientDetails from "./PatientDetails";
import PatientOrderDetails from "./PatientOrderDetails";
import DeliveryInstructions from "./DeliveryInstructions";
import ActionButtons from "./ActionButtons";
import { getApiWithAuth } from "../../utils/api";
import { CREATE_ORDER, ORDER_COMMUNICATION } from "../../utils/apiUrl";
import { useReactToPrint } from "react-to-print";
import Printdoc from "../../components/PDF/PrintDoc";

const SlipPreview = ({ activeTab, pharmacyDetail }) => {
  const { orderData } = useContext(OrderDataContext);
  const { displayMode } = useDisplayMode();
  const userType = localStorage.getItem("userType");
  const superuser = localStorage.getItem("superuser");
  const [pdfFile, setPdfFile] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);
  const [parcelImages, setParcelImages] = useState({});
  const [showPrintdoc, setShowPrintdoc] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [communicationData, setCommunicationData] = useState([]);

  const chatContainerRef = useRef(null);
  const componentRef2 = useRef();

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (activeTab === "communication") {
      getOrderCommunication();
    }
  }, [activeTab]);

  useEffect(() => {
    if (communicationData) {
      scrollToBottom();
    }
  }, [communicationData]);

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };
  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  const categorizeImagesByAttempt = (images) => {
    const categorized = {};

    images?.forEach((image) => {
      const key = image.attempt || "null";
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(image);
    });

    return categorized;
  };

  // Fetch PDF file URL if available
  useEffect(() => {
    if (
      orderData?.order?.order_file &&
      typeof orderData?.order?.order_file === "string"
    ) {
      setPdfFile(orderData?.order?.order_file);
    } else {
      console.error("Invalid order file URL:", orderData?.order?.order_file);
    }
    // Parcel Received by Images
    const receivedImages =
      orderData?.order?.ordermaster_route4me
        ?.filter((item) => item.parcel_recieved_by_image)
        .map((item) => item.parcel_recieved_by_image) || [];
    const receivedVideos =
      orderData?.order?.ordermaster_route4me
        ?.filter((item) => item.video_file)
        .map((item) => item.video_file) || [];
    const attemptsImages = orderData?.order?.ordermaster_delivery;
    const categorizedImages = categorizeImagesByAttempt(attemptsImages);
    console.log("categorizedImagescategorizedImages", categorizedImages);
    const returnReceivedImages =
      orderData?.order?.route_pharmacy_details
        ?.filter((item) => item.return_parcel_received_by_image)
        .map((item) => item.return_parcel_received_by_image) || [];

    setParcelImages({
      receivedImages,
      categorizedImages,
      receivedVideos,
      returnReceivedImages,
    });
  }, [orderData, userType]);

  useEffect(() => {
    console.log(
      "Active tab is:",
      activeTab,
      "Order Status:",
      orderData?.order?.status,
      "Pharmacy pos_type:",
      orderData?.order?.pharmacy?.pos_types,
      "Types:",
      typeof orderData?.order?.status,
      typeof orderData?.order?.pharmacy?.pos_types,
      orderData
    );

    const status = Number(orderData?.order?.status);

    if (activeTab === "signed" && status === 5) {
      const pharmacyType = orderData?.order?.pharmacy?.pos_types;

      if (pharmacyType === "PrimeRX") {
        setShowPrintdoc(true);
      } else if (pharmacyType === "BestRx" || pharmacyType === "PioneerRx") {
        setPdfFile(
          `https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderData?.order?.slug}_signed.pdf?embedded=true#toolbar=0&navpanes=0&scrollbar=0`
        );
      }
    }
  }, [activeTab, orderData]);

  // Handle print action whenever order details change
  useEffect(() => {
    if (orderDetail) {
      handlePrintSlip();
    }
  }, [orderDetail]);

  // Define content for different tabs
  const getSlipContent = () => {
    switch (activeTab) {
      case "uploaded":
        return { title: "Uploaded Slip", images: [] };
      case "signed":
        return { title: "Signed Slip", images: [] };
      // case "delivery":
      //   return {
      //     title: "Delivery Proof",
      //     images: parcelImages,
      //   };
      case "communication":
        return { title: "Communication", images: [] };
      case "previous":
        return { title: "Previous", images: [] };
      default:
        return { title: "Slip Preview", images: [] };
    }
  };

  const getOrderCommunication = async () => {
    try {
      const response = await getApiWithAuth(
        `${ORDER_COMMUNICATION}${orderData?.order?.id}/`
      );
      console.log(response.data);
      if (response.data) {
        setCommunicationData(response.data.data);
      } else {
        console.log("No data found for this order.");
      }
    } catch (error) {
      console.error("Error fetching order communication data:", error);
    }
  };

  const { title, images } = getSlipContent();
  console.log("pharmacyDetail", orderData);
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          minHeight: "92.5%",
          borderRadius: "5px",
          overflowY: activeTab === "uploaded" ? "hidden" : "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        {activeTab === "uploaded" && displayMode === "small" && (
          <Box sx={{ width: "100%", p: 2 }}>
            <DateTimeFilters />
            <PatientDetails />
            <PatientOrderDetails />
            <DeliveryInstructions />
            <ActionButtons />
          </Box>
        )}

        {activeTab === "uploaded" &&
          pdfFile &&
          pdfFile !== "https://pilldrop-s3-bucket.s3.amazonaws.com/null" && (
            <Box sx={{ width: "100%", height: "100%" }}>
              <iframe
                src={`${pdfFile}#toolbar=0&navpanes=0&scrollbar=0`}
                width="100%"
                height="100%"
                style={{ marginTop: 10, border: "none" }}
                title="Uploaded PDF"
              />
            </Box>
          )}

        {/* Delivery Proof Tab */}
        {activeTab === "delivery" && (
          <Box sx={{ width: "100%", p: 2 }}>
            {/* Parcel Received by Images */}
            { }
            {parcelImages.receivedVideos?.length > 0 &&
              userType === "Admin" &&
              superuser &&
              (console.log("parcel recived by images", parcelImages),
                (
                  <>
                    <h4 style={{ color: "black" }}>Signed Video </h4>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {parcelImages.receivedVideos.map((video, index) => (
                        <a
                          href={video}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#126599",
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                        >
                          View Video {index + 1}
                        </a>
                        // <video
                        //   key={index}
                        //   src={video}
                        //   width="400"
                        //   controls
                        //   style={{ border: "1px solid #ccc" }}
                        // >
                        //   Sorry, your browser doesn't support embedded videos.
                        // </video>
                      ))}
                    </Box>
                  </>
                ))}
            {parcelImages.receivedImages?.length > 0 &&
              userType === "Admin" &&
              (console.log("parcel recived by images", parcelImages),
                (
                  <>
                    <h4 style={{ color: "black" }}>Parcel Received</h4>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {parcelImages.receivedImages.map((image, index) => (
                        <Box
                          key={`received-${index}`}
                          component="img"
                          src={image}
                          onClick={() => handleImageClick(image)}
                          alt={`Parcel Received ${index + 1}`}
                          sx={{
                            width: "30%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      ))}
                    </Box>
                  </>
                ))}

            {Object.keys(parcelImages.categorizedImages || {}).map(
              (attempt, index) => (
                <React.Fragment key={attempt}>
                  <h4 style={{ color: "black" }}>
                    Attempts {parseInt(attempt) === 0 ? 1 : parseInt(attempt)}
                  </h4>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    {parcelImages.categorizedImages[attempt].map(
                      (image, index) => (
                        <Box
                          key={`attempts-${index}`}
                          component="img"
                          src={image.delivery_proof_image}
                          onClick={() =>
                            handleImageClick(image?.delivery_proof_image)
                          }
                          alt={`Attempt Count ${index + 1}`}
                          sx={{
                            width: "30%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      )
                    )}
                  </Box>
                </React.Fragment>
              )
            )}

            {/* Return Parcel Received by Images */}
            {parcelImages.returnReceivedImages?.length > 0 &&
              userType === "Admin" && (
                <>
                  <h4 style={{ color: "black" }}>Return Parcel Received</h4>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    {parcelImages.returnReceivedImages.map((image, index) => (
                      <Box
                        key={`return-${index}`}
                        component="img"
                        src={image}
                        alt={`Return Parcel Received ${index + 1}`}
                        onClick={() => handleImageClick(image)}
                        sx={{
                          width: "30%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    ))}
                  </Box>
                </>
              )}
          </Box>
        )}
        {activeTab === "signed" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              width: "100%",
            }}
          >
            {pharmacyDetail?.pos_types === "PrimeRX" ? (
              <Printdoc
                orderDetail={orderData?.order}
                edit={true}
                upload={false}
                printt={false}
                view={false}
                pharmacy={orderData?.order?.pharmacy}
              />
            ) : pharmacyDetail?.pos_types === "BestRx" ||
              pharmacyDetail?.pos_types === "PioneerRx" ? (
              <div style={{ flex: 1 }}>
                {orderData?.order?.status === "5" ? (
                  <iframe
                    src={`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderData?.order.slug}_signed.pdf?embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                    width="100%"
                    height="100%"
                    style={{ border: "none", marginTop: 10 }}
                  />
                ) : (
                  <iframe
                    src={`${orderData?.order?.order_file}#toolbar=0&navpanes=0&scrollbar=0`}
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
        {activeTab === "communication" && (
          <Box sx={{ maxHeight: "90%", overflowY: "auto", width: "100%" }}>
            <ChatWindow messages={communicationData} order={orderData?.order} />
          </Box>
        )}

        {activeTab === "previous" && (
          <Box sx={{ maxHeight: "90%", overflowY: "auto", width: "100%" }}>
            <PreviousOrder />
          </Box>
        )}
      </Box>
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it's above everything else
          }}
          onClick={closeFullscreen}
        >
          <img
            src={selectedImage}
            alt="Fullscreen"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              width: "auto",
              height: "auto",
            }}
          />
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "20px",
              fontSize: "30px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={closeFullscreen}
          >
            ×
          </span>
        </div>
      )}
    </>
  );
};

export default SlipPreview;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Dialog, DialogContent, Button, DialogTitle } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import Printdoc from "../../src/components/PDF/PrintDoc";
import moment from "moment";
import {
  landline,
  mobile,
  iphone,
  buildings,
  route_report,
  locationImg,
  upload,
  pdfLogo,
  registered,
  photoId,
  pharmacyCallBlack,
} from "../assests";
import PatientDetails from "../components/Editorderpage/PatientDetails";
import PatientOrderDetails from "../components/Editorderpage/PatientOrderDetails";
import DeliveryInstructions from "../components/Editorderpage/DeliveryInstructions";
import ActionButtons from "../components/Editorderpage/ActionButtons";
import TabButtons from "../components/Editorderpage/TabButtons";
import SlipPreview from "../components/Editorderpage/SlipPreview";
import DateTimeFilters from "../components/Editorderpage/DateTimeFilters";
import {
  getApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
  patchApiWithAuth,
} from "../utils/api";
import { ALL_PHARMACY_LIST, CREATE_ORDER, MERGE_ORDER } from "../utils/apiUrl";
import { useNavigate } from "react-router-dom";
import { OrderDataContext } from "../context/OrderDataContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
const EditPage = ({ isEditable }) => {
  const [activeTab, setActiveTab] = useState(
    isEditable ? "uploaded" : "signed"
  );
  const { oid, route } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState("");
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [orderResponse, setOrderResponse] = useState({});
  const [addressUpdate, setAddressUpdate] = useState({});
  const [orderDetail, setOrderDetail] = useState(null);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [selectedOrderFile, setSelectedOrderFile] = useState(null);
  const [addressError, setAddressError] = useState(null);
  useEffect(() => {
    setOrderData({ order: {} });
  }, []);
  useEffect(() => {
    // setOrderData({ order: {} });
    getDetailOrder(oid);
  }, [oid]);

  useEffect(() => {
    if (orderData && orderData?.file) {
      const selectedOrderFile = orderData?.order?.order_file;
    }
  }, [orderData]);

  const getDetailOrder = async () => {
    const response = await getApiWithAuth(CREATE_ORDER + `/${oid}`);
    if (response.data !== undefined) {
      setOrderDetail(response.data.data);
      setOrderData((prev) => ({
        order: response.data.data,  // Correct syntax here
      }));
      setPharmacyDetail(response.data.data.pharmacy);
    } else {
      console.log("Error");
    }
  };

  const pid = localStorage.getItem("pharmacyId");
  const navigation = useNavigate();
  const componentRef = useRef(null);

  const reportTemplateRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => {
      if (!componentRef.current) {
        return null;
      }
      return componentRef.current;
    },
    onBeforeGetContent: () => {
      console.log("Before getting content...");
    },
    onAfterPrint: () => {
      console.log("Printing complete.");
      navigation("/order-list");
      setOrderData({ order: {} });
    },
  });
  const componentRef2 = useRef();

  const downloadUploadSlip = () => {
    window.open(`${orderData?.order?.order_file}`, "_blank");
  };

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handleOrderDetailChange = useCallback(
    (updatedData) => {
      console.log("Received updatedData:", updatedData);

      if (!updatedData || typeof updatedData !== "object") {
        console.log("Invalid updatedData, ignoring update.");
        return;
      }

      setOrderData((prevData) => {
        console.log("Previous order data:", prevData);

        const updatedOrderData = {
          ...prevData,
          order: {
            ...prevData.order,
            ...updatedData,
          },
        };

        console.log("Updated order data:", updatedOrderData);

        const hasChanged = Object.keys(updatedData).some(
          (key) => updatedData[key] !== prevData.order[key]
        );

        console.log("Has data changed?", hasChanged);

        if (hasChanged) {
          console.log("Applying update.");
          return updatedOrderData;
        }

        console.log("No changes detected, returning previous data.");
        return prevData;
      });
    },
    [setOrderData]
  );

  React.useEffect(() => {
    const pharmacyId = localStorage.getItem("pharmacyId");
    const userRole = localStorage.getItem("userType");
    if (userRole !== "PharmacyOwner" && userRole !== "PharmacyEmployee") {
      return;
    }
    if (pid !== undefined && parseInt(pid) !== parseInt(pharmacyId)) {
      navigation("/404");
      setOrderData({ order: {} });
    }
  }, [pid, navigation]);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  console.log("routee, routr", route)
  const handleSaveAndPrint = async (event) => {
    event.preventDefault();

    if (route && addressUpdate) {
      setAddressUpdate(false)
      pid !== undefined ? navigation(`/order-list`) : navigation("/dashboard");
      return
    }

    if (orderData?.order?.orderdetail.length === 0) {
      toast.error("Order details are empty. Cannot process the order.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    const fileName = localStorage.getItem("myFile");

    const formData = new FormData();
    console.log("orderdatatatatatatatat", orderData?.order);
    formData.append("deliverydate", orderData?.order?.deliverydate);
    formData.append("order_file_name", orderData?.order?.order_file);
    formData.append("language", orderData?.order?.language || "english");
    formData.append("apart", orderData?.order?.apart);

    console.log("orderData?.deliveryDate", orderData?.deliverAtSelected)
    console.log("orderData?.deliverAtSelected", moment(orderData?.deliverAtSelected).format("MM/DD/YYYY"))
    console.log("orderData?.deliverAtSelected", moment.utc(orderData?.deliverAtSelected).format("MM/DD/YYYY"))
    formData.append(
      "deliveryTime",
      moment(orderData?.order?.deliverAtSelected).format("MM/DD/YYYY")
    );
    formData.append("name", orderData?.order?.name);
    formData.append("address", orderData?.order?.address);
    formData.append(
      "contact",
      orderData?.order?.contact &&
        orderData?.order?.contact !== "(___) ___-____"
        ? orderData?.order?.contact
        : ""
    );
    formData.append(
      "cell",
      orderData?.order?.cell &&
        orderData?.order?.cell !== "(___) ___-____"
        ? orderData?.order?.cell
        : ""
    );
    formData.append(
      "workNumber",
      orderData?.order?.workNumber &&
        orderData?.order?.workNumber !== "(___) ___-____"
        ? orderData?.order?.workNumber
        : ""
    );
    formData.append("zip", orderData?.order?.zip);
    formData.append("notes", orderData?.order?.notes);
    formData.append("status", orderData?.order?.status);
    formData.append("location_image", orderData?.streetViewUrl);
    formData.append("signature", orderData?.order?.signature);
    formData.append("photoid", orderData?.order?.photoid);
    formData.append("refrigrated", orderData?.order?.refrigrated);
    formData.append("collectcopay", orderData?.order?.collectcopay);
    formData.append("hippaform", orderData?.order?.hippaform);
    formData.append("lineform", orderData?.order?.lineform);
    formData.append("nfaqbform", orderData?.order?.nfaqbform);
    formData.append("totalcopay", orderData?.order?.totalcopay);
    console.log(
      "order type",
      orderData?.order?.addresstype,
      orderData?.order?.business_start_time
    );
    formData.append("addresstype", orderData?.order?.addresstype);
    if (orderData?.order?.addresstype) {
      formData.append(
        "business_start_time",
        orderData?.order?.business_start_time || ""
      );
      formData.append(
        "business_end_time",
        orderData?.order?.business_end_time || ""
      );
    } else {
      formData.append("business_start_time", "");
      formData.append("business_end_time", "");
    }
    formData.append("parcelPlace", orderData?.order?.parcelPlace);
    formData.append("lat", orderData?.order?.lat);
    formData.append("lng", orderData?.order?.lng);
    formData.append(
      "daily_order_id",
      parseInt(orderData?.order?.daily_order_id)
    );
    formData.append("order_file", selectedOrderFile || null);
    formData.append("active", orderData?.order?.isActive);

    formData.append("products", JSON.stringify(orderData?.order?.orderdetail));
    try {
      const responsePersonal = await putApiWithAuth(
        CREATE_ORDER + `/${orderData?.order?.id}`,
        formData
      );
      if (
        responsePersonal.data !== undefined &&
        responsePersonal.data.message !== "Duplicate order found"
      ) {
        toast.success("Order Updated Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOrderResponse(responsePersonal.data);
        localStorage.removeItem("myFile");
        localStorage.removeItem("orderData");

      } else if (responsePersonal.data.error) {
        toast.error("Order File Format Does Not Match Our Criteria", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        localStorage.removeItem("myFile");
        localStorage.removeItem("orderData");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      localStorage.removeItem("myFile");
      localStorage.removeItem("orderData");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderResponse && Object.keys(orderResponse).length > 0) {
      if (componentRef && componentRef.current) {
        try {
          handlePrint();
        } catch (error) {
          console.error("Error occurred during handlePrint execution:", error);
        }
      } else {
        console.error(
          "componentRef is not set or invalid. Skipping handlePrint."
        );
      }
    }
  }, [orderResponse]);

  const handleSaveCancelNote = async (note) => {
    const formState = {
      cancel_note: note,
      cancelAt: "time",
    };
    const response = await patchApiWithAuth(
      CREATE_ORDER + `/${orderData?.order?.id}`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Order Cancelled Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      pid !== undefined ? navigation(`/order-list`) : navigation("/dashboard");
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          px: 4,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mx: "auto",
          maxWidth: "1700px",
          height: "calc(100vh - 60px)",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "flex", gap: 4, flexGrow: 1, height: "100%" }}>
          <Box
            sx={{
              flexGrow: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: "775px",
              height: "100%", // Restrict height to container
              overflowY: "auto", // Enable vertical scrolling
              paddingRight: "32px", // Add space on the right for the scrollbar
              "&::-webkit-scrollbar": {
                width: "4px", // Change width of the scrollbar
                backgroundColor: "#fff", // Background color of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#cbd3da", // Color of the scrollbar thumb
                borderRadius: "5px", // Round edges of the scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // Darker color when hovered
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ flexGrow: 1 }}>
                <DateTimeFilters
                  orderData={orderData}
                  onOrderDetailChange={handleOrderDetailChange}
                  isEditable={isEditable}
                />
              </Box>
            </Box>
            <PatientDetails
              pharmacyDetail={pharmacyDetail}
              onOrderDetailChange={handleOrderDetailChange}
              orderData={orderData}
              isEditable={isEditable}
              addressError={addressError}
              setAddressError={setAddressError}
              setAddressUpdate={setAddressUpdate}
            />
            <PatientOrderDetails
              onOrderDetailChange={handleOrderDetailChange}
              orderData={orderData}
              isEditable={isEditable}
            />
            <DeliveryInstructions
              orderData={orderData}
              onOrderDetailChange={handleOrderDetailChange}
              isEditable={isEditable}
            />
            <ActionButtons
              orderData={orderData}
              onSaveAndPrint={handleSaveAndPrint}
              isEditable={isEditable}
              onCancel={handleSaveCancelNote}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "600px",
              maxHeight: "100%",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TabButtons
              activeTab={activeTab}
              onTabChange={handleTabChange}
              orderData={orderData}
              handlePrintSlip={handlePrintSlip}
              pharmacyDetail={pharmacyDetail}
              downloadUploadSlip={downloadUploadSlip}
            />
            <SlipPreview activeTab={activeTab} orderData={orderData} pharmacyDetail={pharmacyDetail} />
          </Box>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <div
          style={{
            margin: "13px",
            padding: "16px",
            maxWidth: "3.75in",
            color: "black",
          }}
          ref={componentRef}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Date: {moment(orderResponse?.createdAt).format("MM/DD/YYYY")}
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time: {moment(orderResponse?.createdAt).format("hh:mm:ss A")}
              </p>
            </div>
            <img
              src={pdfLogo}
              style={{ width: "120px", fontFamily: "Saira, sans-serif" }}
              alt="logo"
            />
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order #: {orderResponse?.daily_order_id}
              </p>
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            <div>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order ID:
                <span style={{ textDecoration: "underline" }}>
                  {orderResponse && orderResponse.slug}
                </span>
              </h3>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                CLIENT:
                <span style={{ textDecoration: "underline", color: "black" }}>
                  {orderResponse?.name}
                </span>
              </h3>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 300,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Rx Count: {orderResponse?.orderdetail?.length}
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                Delivery Preference:
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "0 5px",
                  textTransform: "uppercase",
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                {orderResponse?.parcelPlace}
              </h5>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Delivery Date:{" "}
                {moment(orderResponse?.deliverAtSelected).format("MM-DD-YYYY")}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time Window: {orderResponse?.deliverydate}
              </h4>
              {orderResponse?.notes !== "" && (
                <>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontFamily: "Saira, sans-serif",
                        color: "black",
                      }}
                    >
                      Delivery Note:
                    </span>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      padding: "5px 0",
                      fontFamily: "Saira, sans-serif",
                      width: "2.1in",
                      color: "black",
                    }}
                  >
                    {orderResponse?.notes}
                  </p>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5px 0",
                color: "black",
              }}
            >
              {orderResponse?.totalcopay !== undefined &&
                orderResponse?.totalcopay !== 0 && (
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                      border: "2px solid black",
                      padding: "0 2px",
                      textAlign: "center",
                      marginBottom: "10px",
                      width: "90px",
                      color: "black",
                    }}
                  >
                    COPAY: $ {orderResponse?.totalcopay.toFixed(2)}
                  </p>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {orderResponse && Object.keys(orderResponse).length !== 0 ? (
                  <QRCodeSVG
                    value={orderResponse?.id?.toString()}
                    renderAs="svg"
                    size={80}
                  />
                ) : (
                  <QRCodeSVG renderAs="svg" size={80} value="no Order" />
                )}
                <h5
                  style={{
                    margin: "10px 0",
                    fontSize: "22px",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    fontFamily: "Saira, sans-serif",
                    color: "black",
                  }}
                >
                  {orderResponse.city_code ? (
                    <>
                      {orderResponse.city_code}
                      {orderResponse.zone_code
                        ? ` - ${orderResponse.zone_code}`
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontFamily: "Saira, sans-serif",
              color: "black",
            }}
          >
            {(orderResponse?.hippaform ||
              orderResponse?.lineform ||
              orderResponse?.nfaqbform) && (
                <>
                  <h4
                    style={{
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: 700,
                      padding: "10px 0",
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    Get Forms Signed:
                  </h4>
                  <h5
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      fontWeight: 700,
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    {orderResponse?.hippaform && <span>Hipaa Form</span>}
                    {orderResponse?.lineform && (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontFamily: "Saira, sans-serif",
                          color: "black",
                        }}
                      >
                        Lien Form
                      </span>
                    )}
                    {orderResponse?.nfaqbform && (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontFamily: "Saira, sans-serif",
                          color: "black",
                        }}
                      >
                        NF AOB Form
                      </span>
                    )}
                  </h5>
                </>
              )}
            <div style={{ textAlign: "center", margin: 0 }}>
              {/* Header text */}
              <h4
                style={{
                  margin: 0,
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                {orderResponse?.refrigrated && orderResponse?.photoid
                  ? "Refrigerated / Photo ID"
                  : orderResponse?.photoid
                    ? "Photo ID"
                    : orderResponse?.refrigrated
                      ? "Refrigerated"
                      : ""}
              </h4>

              {/* Icon(s) */}
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {orderResponse?.refrigrated && orderResponse?.photoid ? (
                  <>
                    <img
                      src={registered}
                      style={{ marginRight: "5px" }}
                      alt="Refrigerated Icon"
                    />
                    <img
                      src={photoId}
                      style={{ marginLeft: "5px" }}
                      alt="Photo ID Icon"
                    />
                  </>
                ) : orderResponse?.photoid ? (
                  <img src={photoId} alt="Photo ID Icon" />
                ) : orderResponse?.refrigrated ? (
                  <img src={registered} alt="Refrigerated Icon" />
                ) : null}
              </div>
            </div>
            <h5
              style={{
                margin: "0",
                marginTop: "10px",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              From
            </h5>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {orderResponse?.pharmacy?.name}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {orderResponse?.pharmacy?.location}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              Phone {orderResponse?.pharmacy?.contact_no}
            </h4>
          </div>
        </div>
      </div>

      {(orderDetail && pharmacyDetail ) && (
        <div style={{ display: "none" }}>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "10px",
            }}
            ref={componentRef2}
          >
            <Printdoc orderDetail={orderDetail} edit={true}
                upload={false}
                printt ={true}
                view={false} pharmacy={pharmacyDetail} />
          </div>
        </div>
      )}
    </>
  );
};

export default EditPage;

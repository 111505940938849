import React from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../../components/buttonCompo/Buttons";

const DefaultRouteSetting = ({
  onClose,
  handleSaveSettings,
  formData,
  onChange,
  mapType,
  handleMapTypeChange,
  handleOptimizeChanges,
  optimizeRouteDone,
}) => {
  return (
    <Box
      sx={{
        // height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", fontWeight: 600 }}
          >
            Default Route Setting 2
          </Typography>
        </Box>
        <div style={{ width: "300px" }}>
          {/* Map Type */}
          <FormControl
            component="div"
            sx={{ marginBottom: 1, marginTop: "2%" }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginBottom: "4px",
              }}
            >
              Map Type
            </Typography>
            <RadioGroup
              aria-label="mapType"
              name="mapType"
              value={mapType}
              onChange={handleMapTypeChange}
            >
              <FormControlLabel
                value="LiveMap"
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Live Request"
                sx={{
                  marginBottom: "-6px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="StorePolyline"
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Stored Map"
                sx={{
                  marginTop: "-8px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Sort Order */}
          {(mapType !== "StorePolyline" ||
            formData.optimizeFor !== "MANUAL") && (
            <FormControl
              component="div"
              sx={{ marginBottom: 1, marginTop: "2%" }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#707070",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Sort Order
              </Typography>
              <RadioGroup
                aria-label="routeOption"
                name="routeOption"
                value={formData.sortOrder}
                onChange={(e) => onChange("sortOrder", e.target.value)}
              >
                <FormControlLabel
                  disabled={
                    mapType === "StorePolyline" ||
                    formData.optimizeFor === "MANUAL"
                  }
                  value="ascending"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Ascending"
                  sx={{
                    marginBottom: "-6px",
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="descending"
                  disabled={
                    mapType === "StorePolyline" ||
                    formData.optimizeFor === "MANUAL"
                  }
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Descending"
                  sx={{
                    marginTop: "-8px",
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Optimize For */}
          <FormControl fullWidth component="div" sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginBottom: "4px",
              }}
            >
              Optimize For
            </Typography>
            <RadioGroup
              aria-label="optimizeFor"
              name="optimizeFor"
              value={formData.optimizeFor}
              onChange={(e) => onChange("optimizeFor", e.target.value)}
            >
              <FormControlLabel
                value="TIME"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize by Time"
                sx={{
                  marginBottom: "-6px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="DISTANCE"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize by Mile"
                sx={{
                  marginTop: "-8px",
                  marginBottom: "-6px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="MANUAL"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize Manually"
                sx={{
                  marginTop: "-8px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Route Option */}
          <FormControl fullWidth component="div" sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginBottom: "4px",
              }}
            >
              Route Option
            </Typography>
            <RadioGroup
              aria-label="routeOption"
              name="routeOption"
              value={formData.routeOption}
              onChange={(e) => onChange("routeOption", e.target.value)}
            >
              <FormControlLabel
                value="startToEnd"
                disabled={
                  mapType === "StorePolyline" ||
                  formData.optimizeFor === "MANUAL"
                }
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Start & End At Same Location"
                sx={{
                  marginBottom: "-6px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="endAnywhere"
                disabled={
                  mapType === "StorePolyline" ||
                  formData.optimizeFor === "MANUAL"
                }
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="End At Any Location"
                sx={{
                  marginTop: "-8px",
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Clustering Option */}
          {formData.optimizeFor !== "MANUAL" && (
            <FormControl fullWidth component="div" sx={{ marginBottom: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#707070",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Clustering Option
              </Typography>
              <RadioGroup
                aria-label="clusterOption"
                name="clusterOption"
                value={formData.clusterOption}
                onChange={(e) => onChange("clusterOption", e.target.value)}
              >
                <FormControlLabel
                  value="none"
                  disabled={mapType === "StorePolyline"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="None"
                  sx={{
                    marginBottom: "-6px",
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="topologySegment"
                  disabled={mapType === "StorePolyline"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Topology Segment"
                  sx={{
                    marginTop: "-8px",
                    marginBottom: "-6px",
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="drivingDistance"
                  disabled={mapType === "StorePolyline"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Driving Distance"
                  sx={{
                    marginTop: "-8px",
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
              </RadioGroup>

              {formData.clusterOption === "drivingDistance" && (
                <TextField
                  label="Driving Distance (meters)"
                  type="number"
                  autoComplete="off"
                  value={formData.driving_distance_value}
                  onChange={(e) =>
                    onChange("driving_distance_value", e.target.value)
                  }
                  fullWidth
                  sx={{
                    marginTop: "6px",
                    "& .MuiInputBase-root": {
                      fontSize: "12px",
                      height: "32px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
            </FormControl>
          )}

          {/* Traffic Option */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Traffic Selection */}
            <FormControl component="div" sx={{ flex: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#707070",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Traffic
              </Typography>
              <RadioGroup
                row
                aria-label="traffic"
                name="traffic"
                value={formData.traffic}
                onChange={(e) => onChange("traffic", e.target.value)}
              >
                <FormControlLabel
                  value="enabled"
                  disabled={
                    mapType === "StorePolyline" ||
                    formData.optimizeFor === "MANUAL"
                  }
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Enable"
                  sx={{
                    marginRight: 1,
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="disable"
                  disabled={
                    mapType === "StorePolyline" ||
                    formData.optimizeFor === "MANUAL"
                  }
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Disable"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "13px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>

            {/* DateTime Picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={formData.departureDateTime}
                label="Departure Date & Time"
                onChange={(e) => onChange("departureDateTime", e)}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: { disableUnderline: true },
                    sx: {
                      fontSize: "12px",
                      color: "black",
                      width: "180px",
                      marginTop: "6%",
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "12px",
                    height: "28px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "12px",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
          marginTop: "4%",
        }}
      >
        <OutlinedButtonWithIcon
          label="Optimize"
          disabled={mapType === "StorePolyline"}
          onClick={(e) => {
            handleOptimizeChanges(e);
          }}
        />
        <ContainedButton
          label="Save"
          border="none"
          disabled={mapType === "StorePolyline" || !optimizeRouteDone}
          onClick={() => {
            handleSaveSettings();
          }}
        />
      </Box>
    </Box>
  );
};

export default DefaultRouteSetting;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fade,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Device } from "twilio-client";
import { getApiWithAuth, patchApiWithAuth } from "../../utils/api";
import { AGENTS, VOCIE_CONNECT } from "../../utils/apiUrl";

import CallModel from "../CustomCallModel/CallModel";
import { decodeJWT } from "../../utils/decodeJWT";

function VoiceComponent({ orderCall, setSelectedOrder }) {
  const [loading, setLoading] = useState(null);
  const [device, setDevice] = useState(null);
  const [callStatus, setCallStatus] = useState("ringing");

  const [agent, setAgent] = useState("");
  const [accept, setAccept] = useState(false);
  const [busy, setBusy] = useState(false);
  const [isDeviceReady, setIsDeviceReady] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(null);
  // const [ringtonePlayer, setRingtonePlayer] = useState(new Audio('./ringtown.mp3'));
  const [position, setPosition] = useState({
    x: window.innerWidth - 320,
    y: window.innerHeight - 530,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [callModel, setCallModel] = useState(false);
  const [callerName, setCallerName] = useState("Unknown");
  const [number, setNumber] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [incomingCall, setIncomingCall] = useState(false);

  useEffect(() => {
    if (callModel) {
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setSeconds(0);
    }
  }, [callModel]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, offset]);

  // Function to decode JWT and get expiration time
  const getTokenExpiry = (token) => {
    const decoded = decodeJWT(token);
    return decoded ? decoded.exp * 1000 : null; // Convert to milliseconds
  };

  // Function to fetch a new token
  const fetchNewToken = useCallback(async () => {
    try {
      const set_agent = localStorage.getItem("sessionId");
      const response = await getApiWithAuth(VOCIE_CONNECT, {
        session_id: set_agent,
      });
      if (response.data.status === 200) {
        const newToken = response.data.data.token;
        const expiry = getTokenExpiry(newToken);
        if (expiry) {
          const now = Date.now();
          const timeout = expiry - now - 5 * 60 * 1000; // 5 minutes before expiry
          if (timeout > 0) {
            const timerId = setTimeout(fetchNewToken, timeout);
            setRefreshTimer(timerId);
          }
        }
        if (device) {
          await device.updateToken(newToken);
          console.log("Token updated successfully");
        }
      } else {
        throw new Error("Failed to fetch new token");
      }
    } catch (error) {
      console.error("Error fetching new token:", error);
      toast.error("Error refreshing token. Please reload the page.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [device]);

  useEffect(() => {
    console.log("asdasdas", orderCall, device, isDeviceReady);
    if (orderCall && device && isDeviceReady) {
      setCallModel(true);
      console.log("SDasdasdsa");
      setNumber(orderCall.number);
      setCallStatus("connected");
      if (orderCall?.pharmacyId) {
        device.connect({
          To: orderCall.number,
          order: orderCall?.id,
          agent: agent,
          pharmacy: orderCall?.pharmacyId,
        });
        setCallerName(orderCall?.pharmacyName);
      } else {
        console.log("No pharmacy id");
        device.connect({
          To: orderCall.number,
          order: orderCall?.id,
          agent: agent,
        });
        setCallerName(orderCall?.name);
      }
      setAccept(true);
    }
  }, [orderCall, device, isDeviceReady]);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (agent) {
        await patchApiWithAuth(
          AGENTS + `update-by-session/?session_id=${agent}`,
          { status: "Active" }
        );
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    if (
      userType === "Admin" ||
      userType === "Staff" ||
      userType === "SubAdmin" ||
      userType === "Driver"
    ) {
      let isActive = true;
      console.log("sadas. -s-asdsa");
      if (!device) {
        console.log("sadas. -s-asdsa here---");
        const newDevice = new Device();

        const fetchTokenAndSetupDevice = async () => {
          try {
            if (isActive) setLoading(true);

            let set_agent = localStorage.getItem("sessionId");
            const response = await getApiWithAuth(VOCIE_CONNECT, {
              session_id: set_agent,
            });
            if (response.data.status === 200 && isActive) {
              const token = response.data.data.token;
              newDevice.setup(token, { debug: true });
              setAgent(set_agent);

              await patchApiWithAuth(
                AGENTS + `update-by-session/?session_id=${set_agent}`,
                { status: "Active" }
              );

              const expiry = getTokenExpiry(token);
              if (expiry) {
                const now = Date.now();
                const timeout = expiry - now - 5 * 60 * 1000;
                if (timeout > 0) {
                  const timerId = setTimeout(fetchNewToken, timeout);
                  setRefreshTimer(timerId);
                }
              }

              newDevice.on("ready", () => {
                setIsDeviceReady(true);
              });

              newDevice.on("offline", () => {
                setIsDeviceReady(false);
              });

              newDevice.on("error", async function (error) {
                setCallModel(false);
                setNumber("");
                setCallerName("");
                setAccept(false);
                await patchApiWithAuth(
                  AGENTS + `update-by-session/?session_id=${set_agent}`,
                  { status: "Active" }
                );
                console.error(`Twilio Error: ${error.message}`, {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              });

              newDevice.on("connect", async function (conn) {
                await patchApiWithAuth(
                  AGENTS + `update-by-session/?session_id=${set_agent}`,
                  { status: "Busy" }
                );
                toast.success("Successfully established call!", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              });

              newDevice.on("disconnect", async function (conn) {
                setCallModel(false);
                setNumber("");
                setCallerName("");
                setAccept(false);
                await patchApiWithAuth(
                  AGENTS + `update-by-session/?session_id=${set_agent}`,
                  { status: "Active" }
                );
                if (conn.status() === "pending") {
                  toast.info("Incoming call was cancelled by the caller.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                } else {
                  toast.info("Call ended.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });

              newDevice.on("cancel", async function (conn) {
                if (!accept) {
                  setCallModel(false);
                  setNumber("");

                  setCallerName("");
                  await patchApiWithAuth(
                    AGENTS + `update-by-session/?session_id=${set_agent}`,
                    { status: "Active" } // Set agent back to "Active" on cancel
                  );

                  toast.info("Incoming call was cancelled by the caller.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });

              newDevice.on("incoming", async function (conn) {
                const paramsString = decodeURIComponent(conn.parameters.Params);
                const params = new URLSearchParams(paramsString);
                const callersName = params.get("callerName");
                const busy = params.get("busy");
                if (busy === "true") {
                  conn.reject();
                  toast.warn("Incoming call detected.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                } else {
                  setBusy(true);
                  setCallerName(callersName);
                  setIncomingCall(true);
                  setCallModel(true);
                  setNumber(conn.options.callParameters.From);
                  await patchApiWithAuth(
                    AGENTS + `update-by-session/?session_id=${set_agent}`,
                    { status: "Busy" }
                  );
                  toast.info("Incoming call detected.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });

              if (isActive) setDevice(newDevice);
            } else {
              throw new Error("Failed to fetch token");
            }
          } catch (error) {
            console.error("Error fetching token:", error);
          } finally {
            if (isActive) setLoading(false);
          }
        };

        fetchTokenAndSetupDevice();
      }

      return () => {
        isActive = false;
        if (device) {
          device.destroy();
        }
      };
    }
  }, [device, fetchNewToken, refreshTimer]);

  useEffect(() => {
    if (callModel && accept) {
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    // Reset timer when dialog closes
    else {
      setSeconds(0);
    }
  }, [callModel, incomingCall, accept]);

  useEffect(() => {
    const updateAgentStatus = async () => {
      if (accept && agent !== "") {
        await patchApiWithAuth(
          AGENTS + `update-by-session/?session_id=${agent}`,
          { status: "Busy" }
        );
      }
    };

    const timeoutId = setTimeout(() => {
      updateAgentStatus();
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [accept, agent]);

  // Format the seconds into HH:MM:SS
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .join(":");
  };

  const handleSendDigits = (digits) => {
    if (device && device.activeConnection()) {
      device.activeConnection().sendDigits(digits);
    }
  };

  const hangUp = async () => {
    if (device) {
      device.disconnectAll();
      await patchApiWithAuth(
        AGENTS + `update-by-session/?session_id=${agent}`,
        { status: "Active" }
      );
      setSelectedOrder(null);
    } else {
      console.error("No active device to disconnect calls.");
    }
    setCallModel(false);
    setNumber("");
    setCallerName("");
    setAccept(false);
    setDevice(null);
    if (refreshTimer) {
      clearTimeout(refreshTimer);
      setRefreshTimer(null);
    }
  };

  const acceptCall = async () => {
    if (device && device.activeConnection()) {
      // Accept the call
      device.activeConnection().accept();
      const conn = device.activeConnection();
      const paramsString = decodeURIComponent(conn.parameters.Params);
      const params = new URLSearchParams(paramsString);
      const callSID = params.get("callSID");
      setIncomingCall(false);
      setAccept(true);
      setCallStatus("connected");
      await patchApiWithAuth(
        AGENTS + `update-by-session/?session_id=${agent}&callSID=${callSID}`,
        { status: "Busy" }
      );
    }
  };

  const cancelCall = async () => {
    const connection = device && device.activeConnection();
    if (device) {
      connection.reject();
      setIncomingCall(false);
      setCallModel(false);
      setAccept(false);
      setNumber("");
      setCallerName("");
      await patchApiWithAuth(
        AGENTS + `update-by-session/?session_id=${agent}`,
        { status: "Active" }
      );
      setDevice(null);
      if (refreshTimer) {
        clearTimeout(refreshTimer);
        setRefreshTimer(null);
      }
    }
  };

  const handleCallModelClose = () => {
    hangUp();
    setCallModel(false);
    setNumber("");
    setCallerName("");
  };

  if (!callModel || callStatus === "ended") return null;

  return (
    <>
      {/* {busy ?
            <CustomModel isOpen={callModel} top={true}>
                <div className="phone-busy-modal-body">
                    <p>{callerName}</p>
                    <p>{number}</p>
                </div>
                <div className="phone-busy-modal-footer">
                    {incomingCall ? (
                        <>
                            <button onClick={acceptCall} className='acceptCall'><Phone /></button>
                            <button onClick={cancelCall} className='cancelCall'><CallEndIcon /></button>
                        </>
                    ) : (
                        <button onClick={hangUp} className='hangUp'><CallEndIcon /></button>
                    )}
                </div>
            </CustomModel>
            :
            <CustomModel isOpen={callModel}>
                <div className="phone-modal-header">
                    {callStatus === 'ringing' ? 'Ringing' : 'Connected'}
                </div>
                <div className="phone-modal-body">
                    <p>{callerName}</p>
                    <p>{number}</p>
                    <p>{formatTime(seconds)}</p>
                </div>
                <div className="phone-modal-footer">
                    {incomingCall ? (
                        <>
                            <button onClick={acceptCall} className='acceptCall'><Phone /></button>
                            <button onClick={cancelCall} className='cancelCall'><CallEndIcon /></button>
                        </>
                    ) : (
                        <button onClick={hangUp} className='hangUp'><CallEndIcon /></button>
                    )}
                </div>
            </CustomModel>
            } */}

      {/* <CallModel isOpen={callModel} handleCloseModal={handleCallModelClose} /> */}

      {/* <Dialog
                open={callModel}
                onClose={handleCallModelClose}
                // optional: add a simple transition effect
                TransitionComponent={Fade}
                // Use your own transition duration as needed
                transitionDuration={300}
                sx={{
                    "& .MuiDialog-paper": {
                        position: "fixed",
                        bottom: 16,
                        right: 16,
                        margin: 0,
                        padding: 0,
                        width: "380px",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
            >
                <DialogContent sx={{ p: 0 }}>

                </DialogContent>
            </Dialog> */}

      {callModel && (
        <Box
          sx={{
            position: "fixed",
            left: `${position.x}px`,
            top: `${position.y}px`,
            zIndex: 1000,
            width: "300px",
            borderRadius: "12px",
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            cursor: "grab",
          }}
          onMouseDown={handleMouseDown}
        >
          <Box
            sx={{
              p: 2,
              borderRadius: "12px",
              backgroundColor: "#f7f7f7",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <img
                src="/icons/PersonIcon.svg"
                alt="Profile"
                style={{ marginBottom: 8, height: 48, width: 48 }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, fontSize: "22px" }}
              >
                {callerName || "Unknown"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, fontSize: "18px" }}
              >
                {number || "No Number"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, fontSize: "28px", pt: 1 }}
              >
                {formatTime(seconds)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 3,
                mb: 2,
              }}
            >
              {/* If the call is incoming */}
              {incomingCall ? (
                <>
                  <Button
                    sx={{
                      backgroundColor: "transparent",
                      minWidth: "auto",
                      p: 0,
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    size="large"
                    onClick={acceptCall}
                  >
                    <img src="/icons/Answer.svg" alt="Answer" />
                  </Button>

                  <Button
                    sx={{
                      backgroundColor: "transparent",
                      minWidth: "auto",
                      p: 0,
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    size="large"
                    onClick={cancelCall}
                  >
                    <img src="/icons/Hangup.svg" alt="Hangup" />
                  </Button>
                </>
              ) : (
                <Button
                  sx={{
                    backgroundColor: "transparent",
                    minWidth: "auto",
                    p: 0,
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={hangUp}
                  size="large"
                >
                  <img src="/icons/Hangup.svg" alt="Hang up" />
                </Button>
              )}
            </Box>

            {/* Dial Pad */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 1,
                px: 3,
              }}
            >
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
                (key) => (
                  <Button
                    key={key}
                    onClick={() => handleSendDigits(key)}
                    sx={{
                      backgroundColor: "#fff",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: "8px",
                      color: "#333",
                      py: 1,
                      "&:hover": {
                        backgroundColor: "#eee",
                      },
                    }}
                  >
                    {key}
                  </Button>
                )
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default VoiceComponent;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AvatarModal,
  CancelOrdersPopup,
  NewUpdatePopup,
  TicketsPopup,
  UrgentOrdersPopup,
  MessagesPopup,
  CallsPopup,
  NotificationsPopup,
  RescheduleModal,
} from "./navbarPopups/NavbarPopups";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  AnnouncementIcon,
  BellIcon,
  CallIconNav,
  CrossIcon,
  FlagIcon,
  MessagesIcon,
  MsgIconNav,
  NotificationIcon,
  ReScheduleIcon,
  ReScheduleNavIcon,
  TicketsIcon,
  UrgentOrders,
} from "../Icons";
import { useDisplayMode } from "../../context/DisplayModeContext";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Navbar = ({
  isSidebarCollapsed = false,
  hasSidebar = true,
  hasNavbar = true,
  setSelectedColor,
  setSelectedFontColor,
  setSelectedIconColor,
  setSelectedHoverColor,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { displayMode, toggleDisplayMode } = useDisplayMode();
  const [openTickets, setOpenTickets] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openCalls, setOpenCalls] = useState(false);
  const [openUrgentOrders, setOpenUrgentOrders] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openReSchedule, setOpenReSchedule] = useState(false);
  const [openNewUpdate, setOpenNewUpdate] = useState(false);
  const [openCancelOrders, setOpenCancelOrders] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [openAvatarImage, setOpenAvatarImage] = useState(false);

  const userType = localStorage.getItem("userType");
  const username = localStorage.getItem("userDetail");
  const profilePic = localStorage.getItem("profilePic");

  let userTypeLabel = "";

  const { detail: userDetail, status } = useSelector((state) => state.user);

  switch (userType) {
    case "PharmacyOwner":
      userTypeLabel = "Pharmacy";
      break;
    case "PharmacyEmployee":
      userTypeLabel = "Pharmacy Staff";
      break;
    case "Admin":
      userTypeLabel = "Admin";
      break;
    case "SubAdmin":
      userTypeLabel = "Sub Admin";
      break;
    case "Staff":
      userTypeLabel = "PD Staff";
      break;
    default:
      userTypeLabel = "Unknown";
      break;
  }

  const handleAvatarClick = () => {
    setOpenAvatarImage(true); // Open large image modal
  };

  const handleAvatarModalClose = () => {
    setOpenAvatarImage(false); // Close large image modal
  };
  const handleNameClick = () => {
    setOpenAvatarModal(true); // Open avatar modal
  };

  const handleAvatarModalCloseForName = () => {
    setOpenAvatarModal(false);
  };

  const handleColorChange = (color, fontColor, iconColor, hoverColor) => {
    setSelectedColor(color);
    setOpenAvatarModal(false);
    setSelectedFontColor(fontColor);
    setSelectedIconColor(iconColor);
    setSelectedHoverColor(hoverColor);
  };

  const handleOpen = (event, setOpen) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (setOpen) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [ticketCount, setTicketCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [callCount, setCallCount] = useState(0);
  const [urgentOrderCount, setUrgentOrderCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [reScheduleCount, setReScheduleCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [cancelCount, setCancelCount] = useState(0);


  const generalMenuItems = [
    (userType === "Admin" ||
      userType === "Staff" ||
      userType === "SubAdmin") && (userDetail?.calling_allowed || userType === "Admin") &&
    {
      icon: (fill) => <CallIconNav fill={fill} />,
      count: callCount,
      onClick: (e) => handleOpen(e, setOpenCalls),
    },
    // Only include the message (SMS) menu item if sms_allowed is true
    (userType === "Admin" ||
      userType === "Staff" ||
      userType === "SubAdmin") && (userDetail?.sms_allowed || userType === "Admin") &&
    {
      icon: (fill) => <MsgIconNav fill={fill} />,
      count: messageCount,
      onClick: (e) => handleOpen(e, setOpenMessages),
    },
    ((userType === "Admin" || userType === "SubAdmin")) && {
      icon: (fill) => <ReScheduleNavIcon fill={fill} />,
      count: reScheduleCount,
      onClick: (e) => handleOpen(e, setOpenReSchedule),
    },
    ((userType === "Admin" || userType === "SubAdmin")) && {
      icon: (fill) => <WarningAmberIcon sx={{ color: theme.palette.custom.layoutFontColor }} />,
      count: urgentOrderCount,
      onClick: (e) => handleOpen(e, setOpenUrgentOrders),
    },
    {
      icon: (fill) => <AnnouncementIcon fill={fill} />,
      count: notificationsCount,
      onClick: (e) => handleOpen(e, setOpenNotifications),
    },
    {
      icon: (fill) => <TicketsIcon fill={fill} />,
      count: ticketCount,
      onClick: (e) => handleOpen(e, setOpenTickets),
    },
    {
      icon: (fill) => <FlagIcon fill={fill} />,
      count: updateCount,
      onClick: (e) => handleOpen(e, setOpenNewUpdate),
    },
    {
      icon: (fill) => <BellIcon fill={fill} />,
      count: cancelCount,
      onClick: (e) => handleOpen(e, setOpenCancelOrders),
    },
  ].filter(Boolean);

  const menuItems = generalMenuItems;

  const now = new Date();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  console.log(menuItems, "menuItemsmenuItemsmenuItemsmenuItems");
  const day = dayNames[now.getDay()];
  const date = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();

  const formattedDate = `${day} ${month}-${date}-${year}`;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.custom.layoutBgColor,
          color: theme.palette.custom.layoutFontColor,
          width: hasSidebar
            ? isSidebarCollapsed
              ? ` calc(100% - 65px)`
              : `calc(100% - 240px)`
            : `100%`,
          height: "60px",
          boxShadow: "none",
          overflow: "hidden",
          transition: "width 0.3s ease",
        }}
      >
        <Toolbar
          sx={{ overflow: "hidden", paddingRight: 0, paddingBottom: "5px" }}
        >
          {hasSidebar ? (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontSize: "15px",
                paddingBottom: "3px",
                flexGrow: 1,
                color: theme.palette.custom.layoutFontColor,
              }}
            >
              {formattedDate}
            </Typography>
          ) : (
            <Box
              component="img"
              src="/icons/Logo.svg"
              alt="Logo"
              sx={{
                height: "40px",
                marginRight: "auto",
                marginLeft: "-8px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/order-list")}
            />
          )}

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {hasNavbar &&
              menuItems.map((menuItem, index) => (
                <Box key={index} sx={{ position: "relative" }}>
                  <Button
                    onClick={menuItem.onClick} // API call here will be blocked if hasNavbar is false
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    {menuItem.icon(theme.palette.custom.layoutFontColor)}
                  </Button>
                  {menuItem.count > 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        width: "16px",
                        height: "16px",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {menuItem.count}
                    </Box>
                  )}
                </Box>
              ))}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                src={
                  profilePic && profilePic.trim() !== ""
                    ? profilePic
                    : "/icons/person.svg"
                }
                alt="Profile Image"
                onClick={handleAvatarClick}
                sx={{
                  width: 38.71,
                  height: 37.66,
                  cursor: "pointer",
                }}
              />
              <Box sx={{ cursor: "pointer" }} onClick={handleNameClick}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "11.59px",
                    color: theme.palette.custom.layoutFontColor,
                  }}
                >
                  {username}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "11.59px",
                    color: theme.palette.custom.layoutFontColor,
                  }}
                >
                  {userTypeLabel}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openAvatarImage}
        onClose={handleAvatarModalClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            padding: "16px",
          }}
        >
          <img
            src={
              profilePic && profilePic.trim() !== ""
                ? profilePic
                : "/icons/person.svg"
            }
            alt="Profile Large"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              borderRadius: "8px",
            }}
          />
        </DialogContent>
      </Dialog>
      {/* Popups for each button */}
      {hasNavbar && (
        <TicketsPopup
          open={openTickets}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenTickets)}
          onSeeAllClick={() => {
            navigate("/seealltickets");
            setOpenTickets(false);
            setAnchorEl(null);
          }}
          onCountUpdate={(count) => {
            setTicketCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <MessagesPopup
          open={openMessages}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenMessages)}
          onSeeAllClick={() => {
            navigate("/chats");
            setOpenMessages(false);
            setAnchorEl(null); // Close the popup
          }}
          onCountUpdate={(count) => {
            setMessageCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <CallsPopup
          open={openCalls}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenCalls)}
          onSeeAllClick={() => {
            navigate("/calls");
            setOpenCalls(false);
            setAnchorEl(null); // Close the popup
          }}
          onCountUpdate={(count) => {
            setCallCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <UrgentOrdersPopup
          open={openUrgentOrders}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenUrgentOrders)}
          onCountUpdate={(count) => {
            setUrgentOrderCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <NotificationsPopup
          open={openNotifications}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenNotifications)}
          onSeeAllClick={() => {
            navigate("/announcment-notification");
            setOpenNotifications(false);
            setAnchorEl(null); // Close the popup
          }}
          onCountUpdate={(count) => {
            setNotificationsCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <RescheduleModal
          open={openReSchedule}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenReSchedule)}
        />
      )}
      {hasNavbar && (
        <RescheduleModal
          open={openReSchedule}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenReSchedule)}
        />
      )}
      {hasNavbar && (
        <NewUpdatePopup
          open={openNewUpdate}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenNewUpdate)}
          onSeeAllClick={() => {
            navigate("/updateOrders");
            setOpenNewUpdate(false);
            setAnchorEl(null); // Close the popup
          }}
          onCountUpdate={(count) => {
            setUpdateCount(count);
          }}
        />
      )}
      {hasNavbar && (
        <CancelOrdersPopup
          open={openCancelOrders}
          anchorEl={anchorEl}
          handleClose={() => handleClose(setOpenCancelOrders)}
          onSeeAllClick={() => {
            navigate("/cancelOrders");
            setOpenCancelOrders(false);
            setAnchorEl(null); // Close the popup
          }}
          onCountUpdate={(count) => {
            setCancelCount(count);
          }}
        />
      )}
      <AvatarModal
        open={openAvatarModal}
        handleClose={handleAvatarModalCloseForName}
        onColorSelect={handleColorChange}
        displayMode={displayMode}
        toggleDisplayMode={toggleDisplayMode}
      />
    </>
  );
};

export default Navbar;

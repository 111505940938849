import React, { useState, useEffect, forwardRef, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../App.css";
import { CalendarIcon } from "../Icons";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div
    className="custom-input"
    onClick={onClick}
    ref={ref}
    style={{
      backgroundColor: "#f0f0f0",
      borderRadius: "10px",
      height: "36px",
      padding: "6px 12px",
      fontSize: "11px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      color: "black",
    }}
  >
    <span style={{ flex: 1 }}>{value || "Select Date Range"}</span>
    <CalendarIcon />
  </div>
));

const SingleFieldDateRangePicker = ({ value, onChange, getonlyLastweek=false }) => {
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (value && value.length === 2) {
      setDateRange(value);
    }
  }, [value]);

  const handleDateChange = (update) => {
    const [newStartDate, newEndDate] = update;
    const [currentStartDate, currentEndDate] = dateRange;

    if (
      newStartDate &&
      (!currentStartDate || newStartDate < currentStartDate)
    ) {
      setDateRange([newStartDate, currentEndDate || newStartDate]);
      onChange([newStartDate, currentEndDate || newStartDate]);
    } else if (newEndDate && (!currentEndDate || newEndDate > currentEndDate)) {
      setDateRange([currentStartDate || newEndDate, newEndDate]);
      onChange([currentStartDate || newEndDate, newEndDate]);
    } else {
      setDateRange(update);
      onChange(update);
    }
  };

  const [startDate, endDate] = dateRange;

  const lastWeekSunday = useMemo(() => {
    if (!getonlyLastweek) return undefined;

    const today = new Date();
    let day = today.getDay();
    if (day === 0) day = 7;
    const mondayOfCurrentWeek = new Date(today);
    mondayOfCurrentWeek.setDate(today.getDate() - (day - 1));
    const lastSunday = new Date(mondayOfCurrentWeek);
    lastSunday.setDate(mondayOfCurrentWeek.getDate() - 1);
    return lastSunday;
  }, [getonlyLastweek]);

  return (
    <div className="custom-date-picker">
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        customInput={<CustomInput />}
        dateFormat="MM-dd-yyyy"
        popperPlacement="bottom-start"
        maxDate={getonlyLastweek ? lastWeekSunday : undefined}
      />
    </div>
  );
};

export default SingleFieldDateRangePicker;

import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  Typography,
  TextareaAutosize,
  TextField,
  Avatar,
  Paper,
  Badge,
} from "@mui/material";

import {
  CallIconBlack,
  ChatDottedIcons,
  InfoIconBlack,
  LocationIconBlack,
  MobileIconBlack,
  PencilIconWithBg,
  PersonIconBlack,
  TelephoneIconBlack,
} from "../../../Icons";
import {
  ContainedButtonWithIcon,
  OutlinedButtonWithIcon,
} from "../../../buttonCompo/Buttons";
import VoiceComponent from "../../../TwilioCalls/TwilioCalls";
import {
  getApiWithAuth,
  patchApiWithAuth,
  postApiWithAuth,
} from "../../../../utils/api";
import { ORDER_CHAT_LIST, SEND_TWILIO_SMS } from "../../../../utils/apiUrl";

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

const formatBusinessTime = (timeString) => {
  if (!timeString) {
    return ""; // Return an empty string if no time is provided
  }
  const date = new Date(`1970-01-01T${timeString}`);
  if (isNaN(date.getTime())) {
    return "Invalid time"; // Handle invalid date
  }
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  if (hours === 0) {
    hours = 12; // Handle 12:00 AM / 12:00 PM case
  }
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
};

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const OrderInfo = ({
  isOpenRoutePage,
  showUrgent,
  showRefrigerated,
  showphotoId,
  previousOrder = false,
  handleRouteOrderingModalOpen,
  order,
  routes,
  markReadOrderChat,
  userDetail = null,
  unreadCounts,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [initiateCall, setInitiateCall] = useState(false);
  const [openModalCall, setOpenModalCall] = useState(false);
  const [openModalSMS, setOpenModalSMS] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [route, setRoute] = useState(null);
  const [deliveryImages, setDeliveryImages] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [text, setText] = useState("");
  const [newMessage, setNewMessage] = useState("");

  const endOfMessagesRef = useRef(null);

  const handleOpenModalCall = (order) => {
    setOpenModalCall(true);
    setSelectedOrder(order);
  };

  const ChatMessage = ({ msg }) => (
    <Box
      display="flex"
      justifyContent={msg.type === "outgoing" ? "flex-end" : "flex-start"}
      alignItems="flex-start"
      marginY={1}
    >
      {/* {!isUser && <Avatar sx={{ marginRight: 1 }}>{avatar}</Avatar>} */}
      {msg.type === "incoming" && (
        <Avatar style={{ marginRight: "8px" }}>
          {order?.name ? order.name.charAt(0) : "U"}
        </Avatar>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: msg.type === "outgoing" ? "flex-end" : "flex-start",
          maxWidth: "70%",
        }}
      >
        {msg.type === "incoming" && (
          <Typography
            variant="subtitle2"
            sx={{
              color: "#000",
              marginBottom: 0.5,
              textAlign: msg.type === "outgoing" ? "right" : "left",
              fontSize: "10px",
              fontWeight: 500,
            }}
          >
            {order?.name}{" "}
            <span
              style={{ marginLeft: "30px", fontSize: "8px", color: "#6b6b6b" }}
            >
              {new Date(msg.created_at).toLocaleString()}
            </span>
          </Typography>
        )}
        <Paper
          elevation={2}
          sx={{
            padding: 1,
            backgroundColor: msg.type === "outgoing" ? "#0455a6" : "#f5f5f5",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: msg.type === "outgoing" ? "#fff" : "#000",
              padding: 0.1,
              fontSize: "14px",
            }}
          >
            {msg.body}
          </Typography>
        </Paper>
      </Box>
      {msg.type === "outgoing" && <Avatar sx={{ marginLeft: 1 }}>A</Avatar>}
    </Box>
  );

  const fetchOrderChat = async () => {
    console.log("ww");
    const response = await getApiWithAuth(
      ORDER_CHAT_LIST + `?order_id=${order.id}`
    );
    console.log(response);
    if (response.data !== undefined) {
      scrollToBottom();
      setChatList(response.data.data);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [chatList, isOpen]);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickOpen = () => {
    fetchOrderChat();
    markReadOrderChat(order?.id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenModalSMS = (number) => {
    setOpenModalSMS(true);
    setSelectedNumber(number);
  };

  const handleCloseModalSMS = () => setOpenModalSMS(false);
  const handleCloseModal1 = () => setOpenModalCall(false);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setRoute(order);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setRoute(null);
  };

  const handleMakeCall = (number) => {
    setOpenModalCall(false);
    if (userDetail?.calling_allowed || userDetail?.type?.type === "Admin") {
      setInitiateCall(true);
      setSelectedOrder({
        number: number,
        id: selectedOrder?.id,
        name: selectedOrder?.name,
      });
    } else {
      toast.error("Calling not allowed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const open = Boolean(anchorEl);

  const handleDialogOpen = () => {
    setImageDialogOpen(true);
    setDeliveryImages(
      route?.order?.ordermaster_delivery?.map(
        (item) => item.delivery_proof_image
      ) || []
    );
  };

  const handleDialogClose = () => {
    setImageDialogOpen(false);
  };

  const handleSendTwilioSms = async () => {
    if (selectedNumber !== "") {
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: selectedNumber,
        text: text,
        order_id: selectedOrder.id,
      });
      if (response.data.error === undefined) {
        if (response.data.status === 200) {
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setOpenModalSMS(false);
      setSelectedOrder(false);
      setText("");
      setSelectedNumber(null);
      setOpenModalCall(false);
    }
    setText("");
    setSelectedNumber(null);
    setOpenModalCall(false);
  };
  const userType = localStorage.getItem("userType");
  const handleOrderClick = () => {
    const url = generateActionUrl(order);
    console.log("Opening in new tab:", url);
    window.open(url, "_blank"); // 🚀 Opens in a new tab
  };

  const generateActionUrl = (order) => {
    console.log("Generating Action URL for Order:", order);

    if (!order?.order || !order?.order.id || !order?.order?.status) {
      console.warn("Invalid order data:", order?.order);
      return "/error"; // Handle invalid cases
    }

    console.log("User Type:", userType);
    console.log("Order Status:", order?.order.status);

    if (
      (userType === "PharmacyOwner" || userType === "PharmacyEmployee") &&
      order?.order.status === "1"
    ) {
      console.log("Redirecting to Update Order Page (Status 1)");
      return `${isOpenRoutePage ? `/update/order/${order?.order.id}/true` : `/update/order/${order?.order.id}` }`;
    }

    if (
      (userType === "PharmacyOwner" || userType === "PharmacyEmployee") &&
      order?.order.status !== "1"
    ) {
      console.log("Redirecting to Detail Order Page (Non-Status 1)");
      return `/detail/order/${order?.order.id}`;
    }

    if (["1", "2", "3", "4"].includes(order?.order.status)) {
      console.log("Redirecting to Update Order Page (Status in 1,2,3,4)");
      return `${isOpenRoutePage ? `/update/order/${order?.order.id}/true` : `/update/order/${order?.order.id}` }`;
    }

    console.log("Redirecting to Detail Order Page (Default Case)");
    return `/detail/order/${order?.order.id}`;
  };

  const handleSendTwilioSmsList = async () => {
    if (newMessage !== "") {
      const firstMessage = chatList[0];
      const numberToSend = firstMessage?.clientNumber;
      console.log(numberToSend);
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: numberToSend,
        text: newMessage,
        order_id: order.id,
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      fetchOrderChat();
      setNewMessage("");
    } else {
      toast.error("Please write message for sent", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <Box
      display="flex"
      flex="3"
      maxWidth="579px"
      minWidth="321px"
      flexDirection="column"
      mr={1}
      overflow="hidden"
      height="100%"
    >
      {/* Top Row */}
      <Box>
        {isOpenRoutePage && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ color: "#0b6699", fontSize: "12px", fontWeight: 700 }}
            >
              {order?.order?.pharmacy_name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "132px",
                justifyContent: "flex-end",
                paddingRight: "12px",
                gap: 1,
              }}
            >
              <IconButton
                sx={{ padding: 0 }}
                onClick={() =>
                  handleRouteOrderingModalOpen(
                    order?.number,
                    order?.id,
                    order?.order?.id
                  )
                }
              >
                <PencilIconWithBg />
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  fontSize: "11px",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={handlePopoverOpen} sx={{ padding: 0 }}>
                  <InfoIconBlack />
                </IconButton>
                {order?.order?.totalcopay !== 0 && (
                  <span>
                    "Copay:"
                    <span
                      style={{
                        color: "#00A76f",
                        fontWeight: 600,
                      }}
                    >
                      ${order?.order?.totalcopay.toFixed(2)}
                    </span>
                  </span>
                )}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "460px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            marginBottom={0.5}
            marginRight={2}
          >
            <Typography
              variant="h6"
              fontWeight="700"
              color="primary"
              fontSize="12px"
              sx={{
                textDecoration: "underline",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {isOpenRoutePage ? (
                <span>
                  Order ID{" "}
                  <span
                    onClick={handleOrderClick}
                    style={{
                      cursor: "pointer",
                      // color: "blue",
                      // textDecoration: "underline",
                    }}
                  >
                    {order?.order?.slug
                      ? order?.child_orders?.length > 0
                        ? `${order.child_orders
                          .map((child) => child.slug)
                          .join(", ")}, ${order?.order?.slug}`
                        : order?.order?.slug
                      : order?.slug}
                  </span>
                </span>
              ) : (
                <span
                  onClick={handleOrderClick}
                  style={{
                    cursor: "pointer",
                    // color: "blue",
                    // textDecoration: "underline",
                  }}
                >
                  Order ID {order?.slug}
                </span>
              )}
              {/* {isOpenRoutePage ? (
                <span>
                  Order ID{" "}
                  {order?.order?.slug
                    ? order?.child_orders?.length > 0
                      ? `${order.child_orders.map((child) => child.slug).join(", ")}, ${order?.order?.slug}`
                      : order?.order?.slug
                    : order?.slug}
                </span>
              ) : (
                `Order ID ${order?.slug}`
              )} */}
              <Box>
                {order?.twilioChat && userDetail?.sms_allowed && (
                  <IconButton onClick={handleClickOpen} sx={{ padding: 0 }}>
                    <Badge badgeContent={unreadCounts} color="error">
                      <ChatDottedIcons />
                    </Badge>
                  </IconButton>
                )}

                <Dialog
                  open={isOpen}
                  onClose={handleClose}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle
                    sx={{
                      color: "#0455a6",
                      fontSize: "13.34px",
                      fontWeight: 700,
                      paddingBottom: 0,
                    }}
                  >
                    Order ID {order?.slug}
                  </DialogTitle>
                  <DialogTitle
                    sx={{
                      color: "#05b320",
                      fontSize: "13.34px",
                      fontWeight: 700,
                      paddingTop: 0,
                    }}
                  >
                    {order?.name}
                    <span style={{ color: "black", paddingLeft: "20px" }}>
                      {chatList[0]?.clientNumber}
                    </span>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px",
                      overflowY: "auto",
                      backgroundColor: "#fafafa",
                      padding: 2,
                    }}
                  >
                    {chatList
                      .slice()
                      .reverse()
                      .map((msg) => (
                        <ChatMessage key={msg.id} msg={msg} />
                      ))}
                    <div ref={endOfMessagesRef} />
                  </DialogContent>
                  <DialogActions sx={{ display: "flex", padding: 2 }}>
                    <TextField
                      variant="outlined"
                      placeholder="Type a message..."
                      size="small"
                      autoComplete="off"
                      fullWidth
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSendTwilioSmsList();
                        }
                      }}
                      sx={{ marginRight: 1 }}
                    />
                    <Button
                      sx={{
                        backgroundColor: "#0b6699",
                        color: "#fff",
                        textTransform: "none",
                      }}
                      onClick={(e) => {
                        handleSendTwilioSmsList();
                      }}
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              {order.status === "delivered" ? (
                <CheckCircleIcon
                  style={{
                    color: "green",
                    fontSize: "18px",
                  }}
                />
              ) : (
                order.status === "failed" && (
                  <CancelIcon
                    style={{
                      color: "red",
                      fontSize: "18px",
                    }}
                  />
                )
              )}
            </Typography>

            {!isOpenRoutePage && showUrgent && (
              <Typography
                variant="body2"
                color="#fb0f02"
                border="1px solid #fb0f02"
                borderRadius="6px"
                fontWeight="600"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="12px"
                width="59px"
                height="25px"
              >
                Urgent
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {!isOpenRoutePage && showRefrigerated && !showphotoId && (
              <Typography
                variant="body2"
                color="#0094ff"
                border="1px solid #0094ff"
                borderRadius="6px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize={isOpenRoutePage ? "11px" : "12px"}
                width={isOpenRoutePage ? "100px" : "110px"}
                height={isOpenRoutePage ? "25px" : "30px"}
                fontWeight="700"
                padding={isOpenRoutePage && "1px 1px"}
              >
                Refrigerated
              </Typography>
            )}
            {!isOpenRoutePage && showRefrigerated && showphotoId && (
              <Typography
                variant="body2"
                color="#660159"
                border="1px solid #660159"
                borderRadius="6px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize={isOpenRoutePage ? "11px" : "12px"}
                width={isOpenRoutePage ? "130px" : "146px"}
                height={isOpenRoutePage ? "25px" : "30px"}
                fontWeight="700"
                padding={isOpenRoutePage && "1px 1px"}
              >
                Refrigerated / PhotoID
              </Typography>
            )}
            {!isOpenRoutePage && showphotoId && !showRefrigerated && (
              <Typography
                variant="body2"
                color="#fe9901"
                border="1px solid #fe9901"
                borderRadius="6px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize={isOpenRoutePage ? "11px" : "12px"}
                width={isOpenRoutePage ? "80px" : "80px"}
                height={isOpenRoutePage ? "25px" : "30px"}
                fontWeight="700"
                padding={isOpenRoutePage && "1px 1px"}
              >
                PhotoID
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* Details Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.8,
          maxWidth: "526px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <PersonIconBlack />
          <Typography
            variant="body1"
            color="primary"
            sx={{ fontWeight: 600, fontSize: "11px" }}
          >
            {isOpenRoutePage ? order?.order?.name : order?.name}
          </Typography>
        </Box>

        {/* Phone Numbers */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Contact Field */}
          {/* {order?.contact && order?.contact.trim() !== "" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "33.3%",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenModalCall(order)}
            >
              <CallIconBlack />
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: isOpenRoutePage ? "10px" : "11px",
                }}
              >
                {order?.contact}
              </Typography>
            </Box>
          )} */}
          {isOpenRoutePage ? (
            order?.order?.contact &&
            order?.order?.contact.trim() !== "" &&
            (<Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "33.3%",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenModalCall(order?.order)}
            >
              <CallIconBlack />
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: "11px",
                }}
              >
                {order?.order?.contact}
              </Typography>
            </Box>)
          ) : (
            order?.contact &&
            order?.contact.trim() !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "33.3%",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModalCall(order)}
              >
                <CallIconBlack />
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: "11px",
                  }}
                >
                  {order?.contact}
                </Typography>
              </Box>
            )
          )}

          {/* Cell Field */}
          {isOpenRoutePage ? (
            order?.order?.cell &&
            order?.order?.cell.trim() !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "33.3%",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModalCall(order?.order)}
              >
                <MobileIconBlack />
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: "10px",
                  }}
                >
                  {order?.order?.cell}
                </Typography>
              </Box>
            )
          ) : (
            order?.cell &&
            order?.cell.trim() !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "33.3%",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModalCall(order)}
              >
                <MobileIconBlack />
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: "11px",
                  }}
                >
                  {order?.cell}
                </Typography>
              </Box>
            )
          )}

          {/* Work Number Field */}
          {isOpenRoutePage ? (
            order?.order?.workNumber &&
            order?.order?.workNumber.trim() !== "" &&
            (<Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "33.3%",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenModalCall(order?.order)}
            >
              <TelephoneIconBlack />
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: "10px",
                }}
              >
                {order?.order?.workNumber}
              </Typography>
            </Box>)
          ) : (
            order?.workNumber &&
            order?.workNumber.trim() !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "33.3%",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModalCall(order)}
              >
                <TelephoneIconBlack />
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: "11px",
                  }}
                >
                  {order?.workNumber}
                </Typography>
              </Box>
            )
          )}
        </Box>

        {/* Address */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <LocationIconBlack />
          <Typography
            variant="body2"
            color="primary"
            sx={{
              fontWeight: 600,
              fontSize: isOpenRoutePage ? "10px" : "11px",
            }}
          >
            {isOpenRoutePage ? order?.order?.address : order?.address}
          </Typography>
        </Box>

        {!isOpenRoutePage ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "87%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <InfoIconBlack />
              {order?.apart && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ fontWeight: 600, fontSize: "11px" }}
                >
                  {order?.apart}
                </Typography>
              )}
              {order?.addresstype && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ fontWeight: 500, fontSize: "11px" }}
                >
                  Business Address:{" "}
                  {formatBusinessTime(order?.business_start_time)} to{" "}
                  {formatBusinessTime(order?.business_end_time)}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              {order?.totalcopay !== 0 && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ color: "black", fontSize: "11px", fontWeight: 600 }}
                >
                  Copay:
                  <span style={{ color: "#2bb04b", fontWeight: 600 }}>
                    ${order?.totalcopay.toFixed(2)}
                  </span>
                </Typography>
              )}
              {!previousOrder && order?.ispaidpharmacy && (
                <Typography
                  variant="body2"
                  sx={{ color: "black", fontSize: "11px", fontWeight: 600 }}
                >
                  <span style={{ color: "#2bb04b", fontWeight: 600 }}>
                    Received by pharmacy
                  </span>
                </Typography>
              )}
            </Box>
          </Box>
        )
          :
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "87%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <InfoIconBlack />
              {order?.order?.apart && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ fontWeight: 600, fontSize: "11px" }}
                >
                  {order?.order?.apart}
                </Typography>
              )}
              {order?.order?.addresstype && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ fontWeight: 500, fontSize: "11px" }}
                >
                  Business Address:{" "}
                  {formatBusinessTime(order?.order?.business_start_time)} to{" "}
                  {formatBusinessTime(order?.order?.business_end_time)}
                </Typography>
              )}
            </Box>
          </Box>
        }
      </Box>
      {/* info popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 2, maxWidth: 300, backgroundColor: "black" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Order ID:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {route?.order?.slug}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Coordinates:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {parseFloat(route?.order?.latitude).toFixed(5)},{" "}
              {parseFloat(route?.order?.longitude).toFixed(5)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              ETA:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {routes?.startTime !== null
                ? modifyDateTime(routes?.startTime, route?.estimatedTimeCal)
                : `${floatToTime(route?.estimatedTimeCal)} after start`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Started At:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {routes?.startTime !== null
                ? moment(routes?.startTime).format("hh:mm A")
                : "Not Started"}
            </Typography>
          </Box>
          {route?.status === "delivered" &&
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                >
                  Arrived At:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", color: "white" }}
                >
                  {route?.driver_arrival !== null
                    ? moment(route?.driver_arrival).format("hh:mm A")
                    : "Not Started"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                >
                  Arrival Coordinate:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", color: "white" }}
                >
                  {parseFloat(route?.driver_latitude).toFixed(5)},{" "}
                  {parseFloat(route?.driver_longitude).toFixed(5)}
                </Typography>
              </Box>
            </>}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              {route?.status === "failed" ? "Failed Date" : "Delivery Date"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {route?.status === "delivered"
                ? moment(route?.order?.deliverAt).format("MM-DD-YYYY")
                : route?.status === "failed"
                  ? moment(route?.updated_at).format("MM-DD-YYYY")
                  : "Not Delivered"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              {route?.status === "failed" ? "Failed At" : "Delivered At"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {route?.status === "delivered"
                ? moment(route?.order?.deliverAt).format("hh:mm A")
                : route?.status === "failed"
                  ? moment(route?.updated_at).format("hh:mm A")
                  : "Not Delivered"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Distance:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {parseFloat(route?.distanceCal).toFixed(2)} mi
            </Typography>
          </Box>
          {route?.status === "delivered" && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {route?.order?.driver_name !== "" ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                  >
                    Copay Collected By:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", color: "white" }}
                  >
                    {route?.order?.driver_name}
                  </Typography>
                </>
              ) : (
                route?.driverComment !== "" && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                    >
                      Copay Collected By:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "white" }}
                    >
                      {route?.driverComment}
                    </Typography>
                  </>
                )
              )}
              {route?.order?.receiverName !== "" ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                  >
                    Copay Received By:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", color: "white" }}
                  >
                    {route?.order?.receiverName}
                  </Typography>
                </>
              ) : (
                route?.receiverName !== "" && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                    >
                      Copay Received By:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "white" }}
                    >
                      {route?.receiverName}
                    </Typography>
                  </>
                )
              )}

              {route?.order?.waivedBy !== "" ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                  >
                    Copay Waived By:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", color: "white" }}
                  >
                    {route?.order?.waivedBy}
                  </Typography>
                </>
              ) : (
                route?.waivedBy !== "" && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
                    >
                      Copay Waived By:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "white" }}
                    >
                      {route?.waivedBy}
                    </Typography>
                  </>
                )
              )}
            </Box>
          )}
          {route?.status === "delivered" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
              >
                Signature
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "white" }}
              >
                {route?.eSignature ? "E-Signed" : "Manual"}
              </Typography>
            </Box>
          )}
          {route?.status === "delivered" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={handleDialogOpen}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
              >
                Images:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "white" }}
              >
                {route?.order?.ordermaster_delivery?.length}
              </Typography>
            </Box>
          )}
          {route?.status === "delivered" && route?.order?.failed?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
              >
                Failed reason
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "white" }}
              >
                {route.order.failed[route.order.failed.length - 1]?.comment}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Calls:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {route?.order?.calls}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, color: "white" }}
            >
              Text:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white" }}
            >
              {route?.order?.text}
            </Typography>
          </Box>
        </Box>
      </Popover>

      <Dialog
        open={imageDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Delivery Images</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {deliveryImages.map((image, index) => (
              <Grid item xs={4} key={index}>
                <Box
                  component="img"
                  src={image}
                  alt={`Delivery Image ${index + 1}`}
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 1,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Modal 1 */}
      <Dialog
        open={openModalCall}
        onClose={handleCloseModal1}
        sx={{
          "& .MuiDialog-paper": {
            width: "497px", // Set the width to 497px
            height: "296px", // Set the height to 296px
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            paddingBottom: 0,
            paddingLeft: 4,
            paddingTop: 4,
          }}
        >
          Select a Number for Call
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              maxWidth: "400px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box sx={{ width: "50%", fontSize: "14px", fontWeight: 600 }}>
                Order Id
              </Box>
              <Box sx={{ fontSize: "14px", fontWeight: 600 }}>Name</Box>
            </Box>
            {selectedOrder?.contact && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                {/* Phone Number Section */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: "13px" }}>
                    {selectedOrder.contact}
                  </Typography>
                </Box>

                {/* Buttons Section */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <OutlinedButtonWithIcon
                    label="Message"
                    icon={<img src="./icons/MessageIcon.svg" alt="message" />}
                    width="95px"
                    height="29px"
                    onClick={() => handleOpenModalSMS(selectedOrder?.contact)}
                  >
                    Message
                  </OutlinedButtonWithIcon>
                  <ContainedButtonWithIcon
                    label="Call"
                    width="95px"
                    height="29px"
                    icon={<img src="./icons/CallIcon.svg" alt="message" />}
                    onClick={() => handleMakeCall(selectedOrder?.contact)}
                  >
                    Call
                  </ContainedButtonWithIcon>
                </Box>
              </Box>
            )}
            {selectedOrder?.cell && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                {/* Phone Number Section */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: "13px" }}>
                    {selectedOrder.cell}
                  </Typography>
                </Box>

                {/* Buttons Section */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <OutlinedButtonWithIcon
                    label="Message"
                    icon={<img src="./icons/MessageIcon.svg" alt="message" />}
                    width="95px"
                    height="29px"
                    onClick={() => handleOpenModalSMS(selectedOrder?.cell)}
                  >
                    Message
                  </OutlinedButtonWithIcon>
                  <ContainedButtonWithIcon
                    label="Call"
                    width="95px"
                    height="29px"
                    icon={<img src="./icons/CallIcon.svg" alt="message" />}
                    onClick={() => handleMakeCall(selectedOrder?.cell)}
                  >
                    Call
                  </ContainedButtonWithIcon>
                </Box>
              </Box>
            )}
            {selectedOrder?.workNumber && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                {/* Phone Number Section */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: "13px" }}>
                    {selectedOrder.workNumber}
                  </Typography>
                </Box>

                {/* Buttons Section */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <OutlinedButtonWithIcon
                    label="Message"
                    icon={<img src="./icons/MessageIcon.svg" alt="message" />}
                    width="95px"
                    height="29px"
                    onClick={() =>
                      handleOpenModalSMS(selectedOrder?.workNumber)
                    }
                  >
                    Message
                  </OutlinedButtonWithIcon>
                  <ContainedButtonWithIcon
                    label="Call"
                    width="95px"
                    height="29px"
                    icon={<img src="./icons/CallIcon.svg" alt="message" />}
                    onClick={() => handleMakeCall(selectedOrder?.workNumber)}
                  >
                    Call
                  </ContainedButtonWithIcon>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Modal 2 */}
      <Dialog
        open={openModal2}
        onClose={handleCloseModal2}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            bottom: 100, // Adjust the value as needed
            right: 0, // Adjust the value as needed
            margin: 0, // Remove default margin
            width: "327px", // Set a fixed width if required
            height: "342px", // Set a fixed width if required
            borderRadius: "15px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "25px",
          }}
        >
          <img
            sx={{ mx: "auto" }}
            src="./icons/PersonIcon.svg"
            alt="person"
            style={{ width: "61px" }}
          />
          <DialogTitle sx={{ fontSize: "22px", fontWeight: 600 }}>
            Name IF available
          </DialogTitle>
        </Box>
        <DialogContent sx={{ paddingTop: 0, mx: "auto" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, paddingBottom: 2 }}
            >
              +923365548812
            </Typography>
            <Typography sx={{ fontSize: "28px", fontWeight: 600, mx: "auto" }}>
              00:00:00
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                paddingTop: 2,
              }}
            >
              <img src="./icons/Hangup.svg" alt="hangup" />
              <img src="./icons/Mic.svg" alt="Mic" />
              <img src="./icons/Answer.svg" alt="Answer" />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Call Component */}

      {/* SMS Modal Component */}
      <Dialog
        open={openModalSMS}
        onClose={handleCloseModalSMS}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "250px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">Send Message to Client</Typography>
        </DialogTitle>
        <DialogContent>
          <TextareaAutosize
            fullWidth
            variant="outlined"
            minRows={5}
            size="large"
            name="sms"
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{
              width: "100%",
              fontSize: "12px",
              padding: "5px",
              "& .MuiInputBase-root": {
                fontSize: "12px",
              },
            }}
            placeholder="Write here your message..."
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "30px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleCloseModalSMS}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendTwilioSms}
            variant="contained"
            sx={{
              backgroundColor: "colored",
              color: "colored",
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {initiateCall && (
        <VoiceComponent
          orderCall={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}
    </Box>
  );
};

export default OrderInfo;

import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  BluePersonIcon,
  DoubleTickIcon,
  InfoIcon,
  LocationIconBlack,
  PersonIconBlack,
} from "../Icons";

const TicketStatus = ({ ticket, isActive, onchangedticket, onTicketClick }) => {
  const [clickedId, setClickedId] = useState(null);

  const handleClick = (id) => {
    setClickedId(id); // Set the clicked box's id
    onTicketClick(id); // Trigger the click handler passed as a prop
  };

  return (
    <Box
      display="flex"
      maxWidth="500px"
      // backgroundColor={clickedId === ticket.id ? "#fff" : "#EBEBEC"}
      p={2}
      borderRadius="12px"
      flexDirection="column"
      mr={0.5}
      onClick={() => onTicketClick(ticket.id)}
      sx={{
        backgroundColor: isActive ? "#EBEBEC" : "#fff",
        "&:hover": {
          scale: isActive ? 1.05 : 1.05,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h6"
            fontWeight="700"
            color="#0b6699"
            fontSize="12px"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <BluePersonIcon />
            {ticket?.created_by?.first_name} {ticket?.created_by?.last_name}
          </Typography>
          <Typography
            variant="h6"
            fontWeight="600"
            color="#232323"
            fontSize="12px"
            sx={{ textDecoration: "underline", paddingLeft: "20px" }}
          >
            Order ID {ticket?.order?.slug}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              color: "#e23333",
              width: "auto",
              textAlign: "center",
            }}
          >
            {ticket.ticket_status === "Completed" ? (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  color: "#05b320",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #05b320",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                Ticket Closed
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  color: "#fe9604",
                  border: "1px solid #fe9604",
                  borderRadius: "4px",
                  padding: "5px",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Ticket Pending
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.1,
            width: "50%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PersonIconBlack />
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, color: "#232323", fontSize: "12px" }}
            >
              {ticket?.order?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LocationIconBlack />
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, color: "#232323", fontSize: "12px" }}
            >
              {ticket?.order?.address}
            </Typography>
          </Box>
          {ticket?.order?.apart && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: "#232323", fontSize: "12px" }}
              >
                Apt# {ticket?.order?.apart}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.1 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 600, color: "#232323", fontSize: "12px" }}
          >
            Last Update At :{ticket.updated_at.split("T")[0]}{" "}
            {ticket.updated_at.split("T")[1].split(".")[0]}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: "#232323",
              fontSize: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            By {ticket.created_by.first_name} {ticket.created_by.last_name}
          </Typography>
          {/* <Typography
            variant="body2"
            sx={{ fontWeight: 600, color: "#05b320", fontSize: "13px" }}
          >
            <DoubleTickIcon />
            Recevied
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default TicketStatus;

import React, { useState, useEffect, useRef } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import dayjs from "dayjs";

import TopBar from "../components/routePageCompo/TopBar";
import RouteDetails from "../components/routePageCompo/RouteDetails";

import {
  ALL_ROUTES_WEB,
  MERGE_ROUTE,
  MERGE_ROUTE_RETURN,
  ALL_ZONES_LIST,
  DRIVERS_ROUTE,
} from "../utils/apiUrl";
import {
  getApiWithAuth,
  patchApiWithAuth,
  postApiWithAuth,
} from "../utils/api";

// Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { fetchBoroughs, fetchZones } from "../slices/zoneSlice";
import { fetchDrivers } from "../slices/driverSlice";
import { ContainedButton } from "../components/buttonCompo/Buttons";
import RouteSlipRoute from "../components/PDF/RouteSlipRoute";

const RoutePage = () => {
  const dispatch = useDispatch();

  // Redux State Selectors
  const boroughList = useSelector((state) => state.zone.boroughs);
  const zoneList = useSelector((state) => state.zone.zones);
  const driverList = useSelector((state) => state.driver.list);
  const activeDriverList = useSelector((state) => state.driver.activeList);
  const zoneStatus = useSelector((state) => state.zone.status);
  const driverStatus = useSelector((state) => state.driver.status);
  const zoneError = useSelector((state) => state.zone.error);
  const driverError = useSelector((state) => state.driver.error);

  // Local States
  const [loading, setLoading] = useState(false);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [mergeRouteReturn, setMergerRouteReturn] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRouteIds, setSelectedRouteIds] = useState([]);
  const [zip, setZip] = useState([]);
  const [driver, setDriver] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [hasMore, setHasMore] = useState(null);
  const [search, setSearch] = useState("");
  const [slug, setSlug] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);
  const [selectedRoutes, setSelectedRoutes] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(null);
  const [mergeCheck, setMergeCheck] = useState(false);

  const [formData, setFormData] = useState({
    optimizeFor: "DISTANCE",
    routeOption: "startToEnd",
    clusterOption: "none",
    traffic: "enabled",
    extraMinutes: 0,
    driving_distance_value: 800,
    departureDateTime: dayjs(),
  });

  const componentRef2 = useRef();
  const routeObjRef = useRef(null);

  // Handler to print slips when selectedRoutes change
  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  // Function to handle icon click (for printing or other actions)
  const handleIconClick = (route) => {
    setSelectedRoutes(route);
  };

  // Function to handle route selection (e.g., for merging)
  const handleRouteSelection = (routeId) => {
    if (selectedRouteIds.includes(routeId)) {
      setSelectedRouteIds(selectedRouteIds.filter((id) => id !== routeId));
    } else {
      setSelectedRouteIds([...selectedRouteIds, routeId]);
    }
  };

  let isFetching = false;
  // Function to fetch routes
  const getRoutes = async (pageIndexData = pageIndex, loadMore = false) => {
    if (loadingRoute || isFetching) return;
    setLoadingRoute(true);
    isFetching = true;

    if (!loadMore) {
      if (routeObjRef.current) {
        routeObjRef.current.scrollTop = 0;
      }
      setPageIndex(1);
    }

    const queryParams = { zip, driver };
    const [startDate, endDate] = dateRange;

    const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
    const formattedEndDate = moment(endDate).format("MM-DD-YYYY");
    if (search === "" && slug === "") {
      queryParams.from = formattedStartDate;
      queryParams.to = formattedEndDate;
    }

    try {
      const response = await getApiWithAuth(
        `${ALL_ROUTES_WEB}?page=${!loadMore ? 1 : pageIndexData
        }&pageSize=${pageSize}&dated_response=true&search=${search}&slug=${slug}`,
        queryParams
      );

      if (response.data && response.data.data && response.data.data.data) {
        let groupedRoutes = {};
        response.data.data.data.forEach((route) => {
          const createdAtDate = new Date(route.createdAt).toLocaleDateString();
          if (!groupedRoutes[createdAtDate]) {
            groupedRoutes[createdAtDate] = [route];
          } else {
            groupedRoutes[createdAtDate].push(route);
          }
        });

        const newGroupedRoutesArray = Object.keys(groupedRoutes).map(
          (date) => ({
            date,
            data: groupedRoutes[date],
          })
        );

        let newFilteredRoutes = newGroupedRoutesArray;

        if (loadMore) {
          setRoutes((prevRoutes) => {
            const updatedRoutes = [...prevRoutes];
            newFilteredRoutes.forEach((newGroup) => {
              const existingGroupIndex = updatedRoutes.findIndex(
                (group) => group.date === newGroup.date
              );
              if (existingGroupIndex !== -1) {
                updatedRoutes[existingGroupIndex].data = [
                  ...updatedRoutes[existingGroupIndex].data,
                  ...newGroup.data,
                ];
              } else {
                updatedRoutes.push(newGroup);
              }
            });
            return updatedRoutes;
          });
        } else {
          setRoutes(newFilteredRoutes);
        }

        setPageIndex((prevPageIndex) => prevPageIndex + 1);
        setPageSize(response.data.data.pagination.pageSize);
        setTotalPages(response.data.data.pagination.totalPages);
        setTotalItems(response.data.data.pagination.totalItems);
        setHasMore(response.data.data.pagination.hasMore);
        setTotalLength(
          (prevLength) => prevLength + response.data.data.data.length
        );
      } else {
        setHasMore(false);
        setTotalLength(0);
      }
    } catch (error) {
      setHasMore(false);
    }

    setLoading(false);
    setLoadingRoute(false);
    isFetching = false;
  };

  // Function to handle search input (Enter key)
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      if (inputValue.includes("-")) {
        setSlug(inputValue);
      } else {
        setSearch(inputValue);
      }
    }
  };

  // Function to fetch drivers
  const getDrivers = async () => {
    try {
      await dispatch(fetchDrivers());
      if (driverError) {
        toast.error(`Backend Error for get driver list!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch {
      toast.error(`Backend Error for get driver list!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Initial data fetching
  useEffect(() => {
    dispatch(fetchBoroughs());
    getDrivers();
  }, [dispatch]);

  useEffect(() => {
    getRoutes();
  }, [search, slug, zip, driver, dateRange]);

  // Infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (!routeObjRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = routeObjRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 250 && hasMore) {
        getRoutes(pageIndex, true);
      }
    };

    const div = routeObjRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, loadingRoute, pageIndex]);

  // Fetch route settings on mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getApiWithAuth("/api/route/settings/");
        if (response.data) {
          setFormData({
            ...response.data.data,
            driving_distance_value:
              response.data.data.driving_distance_value || 800,
            departureDateTime: response.data.data.departureDateTime
              ? dayjs(response.data.data.departureDateTime)
              : dayjs(),
          });
        }
      } catch (error) {
        console.error("Failed to fetch settings", error);
      }
    };
    fetchSettings();
  }, []);

  // Example of attaching event listeners to custom date picker if needed
  useEffect(() => {
    const inputElement = document.getElementById("customDatePicker");

    if (inputElement) {
      inputElement.addEventListener("click", () => {
        inputElement.click();
      });
    }
  }, []);

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  // Calculate start and end items for pagination display
  const startItem = (pageIndex - 1) * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  useEffect(() => {
    if (selectedRoutes) {
      handlePrintSlip();
    }
  }, [selectedRoutes]);

  const handleMergeRoute = async () => {
    setLoading(true);
    setMergeCheck(false);
    try {
      const routeOrderResponse = await postApiWithAuth(MERGE_ROUTE, {
        routeIds: selectedRouteIds,
      });

      if (routeOrderResponse.data.status === 201) {
        toast.success(`Route merged successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
      } else {
        toast.error(`Backend Error!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
      }
    } catch (error) {
      toast.error(`An error occurred while merging routes!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
    }
    setLoading(false);
    setSelectedRouteIds([]);
  };

  // Function to handle merging routes on return
  const handleMergeRouteReturn = async () => {
    setLoading(true);
    try {
      const routeOrderResponse = await postApiWithAuth(MERGE_ROUTE_RETURN, {
        routeIds: selectedRouteIds,
      });

      if (routeOrderResponse.data.status === 201) {
        toast.success(`Route merged successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setMergerRouteReturn(false);
      } else {
        toast.error(`Backend Error!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setMergerRouteReturn(false);
      }
    } catch (error) {
      toast.error(`An error occurred while merging routes!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setMergerRouteReturn(false);
    }
    setLoading(false);
    setSelectedRouteIds([]);
  };

  // Function to handle saving route settings
  const handleSaveSettings = async () => {
    try {
      const payload = {
        ...formData,
        departureDateTime: formData.departureDateTime.toISOString(),
      };
      const response = await patchApiWithAuth("/api/route/settings/", payload);
      if (response.data.status === 200) {
        toast.success("Settings saved successfully", {
          position: "top-right",
          autoClose: 500,
        });
        setSettingsOpen(null);
      } else {
        toast.error("Failed to save settings", {
          position: "top-right",
          autoClose: 500,
        });
      }
    } catch (error) {
      toast.error("An error occurred while saving settings", {
        position: "top-right",
        autoClose: 500,
      });
    }
  };

  const handleBoroughChange = async (selectedBoroughId) => {
    if (selectedBoroughId) {
      try {
        const result = await dispatch(fetchZones(selectedBoroughId));
        if (fetchZones.fulfilled.match(result)) {
          const zones = result.payload;

          const zipcodes = zones
            .filter((zone) => zone.zipcode && zone.zipcode.length > 0)
            .flatMap((zone) => zone.zipcode.map((zipcode) => zipcode.zipcode));

          setZip(zipcodes);
        } else {
          setZip([]);
        }
      } catch (error) {
        setZip([]);
      }
    } else {
      setZip([]);
    }
  };

  const handleZipChange = (selectedZoneId) => {
    if (selectedZoneId) {
      const selectedZoneObj = zoneList.find(
        (zone) => zone.id === selectedZoneId
      );

      if (selectedZoneObj) {
        const zipcodes = selectedZoneObj.zipcode.map((item) => item.zipcode);

        setZip(zipcodes);
      } else {
        setZip([]);
      }
    } else {
      setZip([]);
    }
  };

  const handleDriverChange = (selectedDriverId) => {
    if (selectedDriverId) {
      setDriver(selectedDriverId);
    } else {
      setDriver(null);
    }
  };

  const handleMergeCheck = () => {
    setMergeCheck(!mergeCheck);
  };

  return (
    <Box sx={{ height: "90vh", display: "flex", flexDirection: "column" }}>
      <TopBar
        boroughList={boroughList}
        zoneList={zoneList}
        activeDriverList={activeDriverList}
        driverList={driverList}
        dateRange={dateRange}
        onBoroughChange={handleBoroughChange}
        onZoneChange={handleZipChange}
        onDriverChange={handleDriverChange}
        handleDateChange={handleDateChange}
        handleSearchKeyDown={handleSearchKeyDown}
        handleMergeRoute={handleMergeRoute}
        mergeCheck={mergeCheck}
        handleMergeCheck={handleMergeCheck}
        handleSaveSettings={handleSaveSettings}
        formData={formData}
        setFormData={setFormData}
      />

      <Box
        className="route-obj"
        ref={routeObjRef}
        sx={{ overflowY: "auto", flexGrow: 1, padding: 2 }}
      >
        {routes.length === 0 && !loadingRoute && (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <p>No routes available.</p>
          </Box>
        )}
        {routes.map((group, i) => (
          <Box key={`group-${i}`} sx={{ mb: 0.5 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div />
              <Typography
                sx={{ color: "gray", fontSize: "12px", padding: 0, mb: 0.5 }}
              >
                {`${group.date}`}
              </Typography>
              <img
                src='/icons/reportsIcons/Print.svg'
                onClick={() => handleIconClick(group.data)}
                style={{ marginRight: '30px', marginBottom: '3px', cursor: 'pointer' }}
                alt="print"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginLeft: "-1%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                {group.data.map((route) => (
                  <Box
                    key={route.id}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    {mergeCheck && route.type === "Order" && (
                      <Checkbox
                        autoComplete="off"
                        type="checkbox"
                        checked={selectedRouteIds.includes(route.id)}
                        onChange={() => handleRouteSelection(route.id)}
                      />
                    )}
                    <RouteDetails
                      route={route}
                      onIconClick={() => handleIconClick(route)}
                      onMergeClick={handleMergeCheck}
                      driverList={activeDriverList}
                      getRoutes={getRoutes}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ))}

        {loadingRoute && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <p>Loading routes...</p>
          </Box>
        )}
      </Box>
      <div style={{ display: 'none' }}>
        {selectedRoutes && (
          <div ref={componentRef2}>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px', pageBreakBefore: 'always', pageBreakAfter: 'always', pageBreakInside: 'avoid' }} >
              <RouteSlipRoute routes={selectedRoutes} />
            </div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default RoutePage;

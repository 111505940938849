import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { pdfLogo, registered, photoId } from "../../../../assests";
import { OrderDataContext } from "../../../../context/OrderDataContext";
import HistoryComponent from "../../popups/HistoryComponent";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../../../buttonCompo/Buttons";

import {
  GET_HISTORY,
  CREATE_ADMIN_NOTE,
  DRIVER_NOTE,
  CREATE_PHARMACY_NOTE,
  CREATE_ORDER,
} from "../../../../utils/apiUrl";
import { getApiWithAuth, putApiWithAuth } from "../../../../utils/api";
import { toast } from "react-toastify";
import NewPrintDocList from "../../../PDF/NewPrintDocList";
import VoiceComponent from "../../../TwilioCalls/TwilioCalls";
import ConfirmationDialog from "../../../Ui/ConfirmationDialog";
import CustomDropdown from "../../../CustomDropdown";

const ActionIcons = ({ order, fetchorders, userDetail }) => {
  const userType = localStorage.getItem("userType");
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [newDate, setNewDate] = useState("");

  const handleRescheduleOpen = () => {
    setRescheduleOpen(true);
  };

  const handleRescheduleClose = () => {
    setRescheduleOpen(false);
  };

  const handleConfirmReschedule = () => {
    console.log("New Delivery Date:", newDate);
    setRescheduleOpen(false);
  };

  const actions = [
    {
      src: "icons/order/EyeBlack.svg",
      alt: "View/Edit Order",
      label: "View/Edit Order",
    },
    {
      src: "icons/order/PrinterBlack.svg",
      alt: order.status === "5" ? "Signed Slip" : "QR Slip",
      label: order.status === "5" ? "Signed Slip" : "QR Slip",
    },
    {
      src: "icons/order/NotesBlack.svg",
      alt: "Staff Notes",
      label: "Staff Notes",
      labelStyle:
        (order.pharmacy_staff_note &&
          (userType === "PharmacyOwner" || userType === "PharmacyEmployee")) ||
        (order.admin_staff_note &&
          userType !== "PharmacyOwner" &&
          userType !== "PharmacyEmployee")
          ? { color: "blue" }
          : { color: "black" },
    },
    userType === "PharmacyEmployee" || userType === "PharmacyOwner"
      ? {
          src: "icons/order/NotesBlack.svg",
          alt: "Note from Pilldrop",
          label: "Note from Pilldrop",
          labelStyle: {
            color: order.admin_note ? "blue" : "black",
          },
        }
      : {
          src: "icons/order/NotesBlack.svg",
          alt: "Note to Pharmacy",
          label: "Note to Pharmacy",
          labelStyle: {
            color: order.admin_note ? "blue" : "black",
          },
        },
    { src: "icons/order/HistoryBlack.svg", alt: "History", label: "History" },
    {
      src: "icons/order/NotesBlack.svg",
      alt: "Reschedule",
      label: "Reschedule",
    },
  ];
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [adminNote, setAdminNote] = useState("");
  const visibleActions = showAll ? actions : actions.slice(0, 10);
  const [orderDetail, setOrderDetail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElpill, setAnchorElpill] = useState(null);
  const [anchorElpilladmin, setAnchorElpilladmin] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [history, setHistory] = useState(null);
  const [pharmacyNote, setPharmacyNote] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [loadingHistory, setLoadingHistory] = useState(false);
  const { setOrderData } = useContext(OrderDataContext);
  const componentRef = useRef(null);
  const componentRef2 = useRef(null);
  const [driverNotes, setDriverNotes] = useState("");
  const reportTemplateRef = useRef(null);
  const [pharmacyCallModel, setPharmacyCallModel] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [callingOrder, setCallingOrder] = useState({});
  const [callModel, setCallModel] = useState(null);
  const [timer, setTimer] = useState("");

  useEffect(() => {
    if (order?.admin_note) {
      setPharmacyNote(order.admin_note);
    }
  }, [order?.admin_note]);

  const getOrderDetail = async () => {
    const response = await getApiWithAuth(CREATE_ORDER + `/${order.id}`);
    if (response.data !== undefined) {
      setOrderDetail(response.data.data);
      setPharmacy(response.data.data.pharmacy);
    } else {
      console.log("Error");
    }
  };

  const handleSavePharmacyNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (pharmacyNote !== "") {
      formData.append("note", pharmacyNote);
      const response = await putApiWithAuth(
        CREATE_PHARMACY_NOTE + `${order.id}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        toast.success("Note Saved Successfully");
        handleClosepilladmin();
        fetchorders();
      } else {
        handleClosepilladmin();
        fetchorders();
      }
    } else {
      toast.error("Add note for save");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => {
      if (!componentRef.current) {
        return null;
      }
      return componentRef.current;
    },
    onBeforeGetContent: () => {
      console.log("Before getting content...");
    },
    onAfterPrint: () => {
      navigate("/order-list");
    },
  });

  useEffect(() => {
    handlePrintSlip();
  }, [orderDetail]);

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const fetchDriverNots = async () => {
    const response = await getApiWithAuth(
      DRIVER_NOTE + `?order_id=${order.id}&is_pharmacy=${true}`
    );
    console.log(response);
    if (response.data !== undefined) {
      setDriverNotes(response.data.data);
    } else {
      console.log("error");
    }
  };

  const handleToggle = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const handleClick = (event) => {
    setAdminNote(
      userType === "PharmacyOwner" || userType === "	PharmacyEmployee"
        ? order.pharmacy_staff_note
        : order.admin_staff_note
    );
    setAnchorEl(event.currentTarget);
  };

  const handleClickpill = (event) => {
    fetchDriverNots();
    setAnchorElpill(event.currentTarget);
  };

  const handleClickpilladmin = (event) => {
    fetchDriverNots();
    setAnchorElpilladmin(event.currentTarget);
  };

  const handleSaveFacilityNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (adminNote !== "") {
      formData.append("note", adminNote);
      const response = await putApiWithAuth(
        CREATE_ADMIN_NOTE + `${order.id}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        setAdminNote("");
        fetchorders();
        handleClose();
      } else {
        handleClose();
        fetchorders();
      }
    } else {
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosepill = () => {
    setAnchorElpill(null);
  };
  const handleClosepilladmin = () => {
    setAnchorElpilladmin(null);
  };

  const handleHistoryOpen = () => {
    setHistoryModalOpen(true);
    handleCreateHistory();
  };

  const handleCreateHistory = async () => {
    try {
      const response = await getApiWithAuth(GET_HISTORY + `${order.id}/`);

      if (response.data !== undefined) {
        setHistory(response.data.data);
        setHistoryModalOpen(true);
      } else {
        console.log("No history data found");
      }
    } catch (error) {
      console.log("Error fetching history:", error);
    } finally {
      setLoadingHistory(false); // Set loading state to false
    }
  };

  const handleHistoryClose = () => {
    setHistoryModalOpen(false);
  };

  const handleSignedSlip = () => {
    if (order.pharmacy?.pos_types === "PrimeRX") {
      getOrderDetail();
    } else {
      window.open(
        `https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${order.slug}_signed.pdf?embedded=true`,
        "_blank"
      );
    }
  };

  const generateActionUrl = () => {
    if (
      (userType === "PharmacyOwner" || userType === "PharmacyEmployee") &&
      order.status === "1"
    ) {
      return `/update/order/${order.id}`;
    }
    if (
      (userType === "PharmacyOwner" || userType === "PharmacyEmployee") &&
      order.status !== "1"
    ) {
      return `/detail/order/${order.id}`;
    }
    if (["1", "2", "3", "4"].includes(order.status)) {
      return `/update/order/${order.id}`;
    }
    return `/detail/order/${order.id}`;
  };

  const handlePharmacyCallModelOpen = (number) => {
    console.log("sssss", userDetail);
    if (userDetail?.calling_allowed || userDetail?.type?.type === "Admin") {
      console.log("asdasdsa");
      setPharmacyCallModel(true);
      setSelectedNumber(number);
    } else {
      toast.error("Calling not allowed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePharmacyCallModelClose = () => {
    setPharmacyCallModel(false);
    setSelectedNumber("");
  };

  const handlePharmacyMakeCall = () => {
    if (userDetail?.calling_allowed || userDetail?.type?.type === "Admin") {
      handleHistoryClose();
      setPharmacyCallModel(false);
      setSelectedNumber("");
      setCallingOrder({
        id: history?.orderDetail?.id,
        name: history?.orderDetail?.name,
        number: selectedNumber,
        pharmacyId: history?.orderDetail?.pharmacy?.id,
        pharmacyName: history?.orderDetail?.pharmacy?.name,
      });
      setCallModel(true);
    } else {
      toast.error("Calling not allowed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openpill = Boolean(anchorElpill);
  const idpill = open ? "simple-popover" : undefined;
  const openpilladmin = Boolean(anchorElpilladmin);
  const idpilladmin = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        height="100%"
        display="flex"
        width="250px"
        minWidth="100px"
        flexDirection="column"
        flexWrap="wrap"
        columnGap={2}
        rowGap={0}
        alignContent="center"
        sx={{
          "& > div:not(:last-of-type)": {
            marginBottom: "3px",
          },
        }}
      >
        {visibleActions.map((action, index) => (
          <Box key={index} display="flex" alignItems="center">
            <img src={action.src} alt={action.alt} style={{ marginRight: 8 }} />
            <Button
              variant="text"
              color="primary"
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                padding: 0,
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                ...action.labelStyle,
              }}
              onClick={(event) => {
                if (action.label === "History") {
                  handleHistoryOpen();
                } else if (action.label === "Staff Notes") {
                  handleClick(event);
                } else if (action.label === "View/Edit Order") {
                  const url = generateActionUrl();
                  // console.log("Navigating to:", url);
                  // setOrderData((prev) => ({
                  //   order,
                  // }));
                  // navigate(url, { replace: true });
                  window.open(url, "_blank");
                } else if (action.label === "QR Slip") {
                  handlePrint();
                } else if (action.label === "Signed Slip") {
                  handleSignedSlip();
                } else if (action.label === "Note from Pilldrop") {
                  handleClickpill(event);
                } else if (action.label === "Note to Pharmacy") {
                  handleClickpilladmin(event);
                } else if (action.label === "Reschedule") {
                  handleRescheduleOpen();
                }
              }}
            >
              {action.label}
            </Button>
          </Box>
        ))}

        {/* <Box display="flex" alignItems="center">
          <img
            src="icons/order/InfoBlack.svg"
            alt={showAll ? "See Less" : "See All"}
            style={{ marginRight: 3 }}
          />
          <Button
            variant="text"
            size="small"
            onClick={handleToggle}
            color="primary"
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              padding: 0,
              paddingLeft: 0.7,
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
          >
            {showAll ? "See Less" : "See All"}
          </Button>
        </Box> */}
        {/* Modal for History */}
        <Modal
          open={historyModalOpen}
          onClose={handleHistoryClose}
          aria-labelledby="history-modal"
          aria-describedby="history-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              bottom: 0, // Distance from the bottom of the screen
              right: 0,
              // transform: "translate(-50%, -50%)",
              width: 650,
              bgcolor: "background.paper",
              backgroundColor: "#f4f6f8",
              boxShadow: 24,
              overflowY: "auto",
              p: 2,
            }}
          >
            <HistoryComponent
              orderHistory={history}
              onClose={handleHistoryClose}
              handlePharmacyCallModelOpen={handlePharmacyCallModelOpen}
            />
          </Box>
        </Modal>

        {/* Staff Notes Popover */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              padding: 1,
              borderRadius: "10px",
              width: "254px",
              // height: "121px",
              backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
            Staff Notes
          </Typography>
          <Typography variant="body2"></Typography>
          <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            multiline
            rows={5}
            value={adminNote}
            onChange={(e) => setAdminNote(e.target.value)}
            sx={{
              mb: 2,
            }}
            InputProps={{
              sx: {
                padding: 0, // Remove padding from the outer wrapper
                "& .MuiInputBase-input": {
                  padding: 0, // Remove padding from the input field itself
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the notched outline (border)
                },
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <OutlinedButtonWithIcon
              label="Clear"
              onClick={handleClose}
              width="46px"
              height="20px"
              padding={1.3} // Ensure padding is set as desired
            />
            <ContainedButton
              label="Save"
              onClick={handleSaveFacilityNote}
              width="46px"
              height="20px"
            >
              Save
            </ContainedButton>
          </Box>
        </Popover>
        <Popover
          id={idpill}
          open={openpill}
          anchorEl={anchorElpill}
          onClose={handleClosepill}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              padding: 1,
              borderRadius: "10px",
              width: "254px",
              // height: "121px",
              backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
            Note from PillDrop
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            multiline
            rows={5}
            value={order.admin_note}
            sx={{
              mb: 2,
            }}
            InputProps={{
              sx: {
                padding: 0, // Remove padding from the outer wrapper
                "& .MuiInputBase-input": {
                  padding: 0, // Remove padding from the input field itself
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the notched outline (border)
                },
              },
            }}
          />

          {driverNotes?.length > 0 &&
            driverNotes.map((note, i) => (
              <Typography key={i} variant="body2" className="driverNote">
                {note.note}
              </Typography>
            ))}

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <OutlinedButtonWithIcon
              label="Clear"
              onClick={handleClosepill}
              width="46px"
              height="20px"
              padding={1.3} // Ensure padding is set as desired
            />
          </Box>
        </Popover>
        <Popover
          id={idpilladmin}
          open={openpilladmin}
          anchorEl={anchorElpilladmin}
          onClose={handleClosepilladmin}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              padding: 1,
              borderRadius: "10px",
              width: "254px",
              // height: "121px",
              backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
            Note for Pharmacy
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            multiline
            rows={5}
            value={pharmacyNote}
            onChange={(e) => setPharmacyNote(e.target.value)}
            sx={{
              mb: 2,
            }}
            InputProps={{
              sx: {
                padding: 0, // Remove padding from the outer wrapper
                "& .MuiInputBase-input": {
                  padding: 0, // Remove padding from the input field itself
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the notched outline (border)
                },
              },
            }}
          />

          {driverNotes?.length > 0 &&
            driverNotes.map((note, i) => (
              <Typography key={i} variant="body2" className="driverNote">
                {note.note}
              </Typography>
            ))}

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <OutlinedButtonWithIcon
              label="Cancel"
              onClick={handleClosepilladmin}
              width="90px"
              height="40px"
              padding={1.3} // Ensure padding is set as desired
            />
            {/* <ContainedButton
              label="Save"
              onClick={handleSaveFacilityNote}
              width="46px"
              height="20px"
            >
              Save
            </ContainedButton> */}
            <OutlinedButtonWithIcon
              label="Send to Pharmacy"
              onClick={handleSavePharmacyNote}
              width="90px"
              height="40px"
              padding={1.3} // Ensure padding is set as desired
            />
          </Box>
        </Popover>
      </Box>

      <div style={{ display: "none" }}>
        <div
          style={{
            margin: "13px",
            padding: "16px",
            maxWidth: "3.75in",
            color: "black",
          }}
          ref={componentRef}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Date: {moment(order?.createDate).format("MM/DD/YYYY")}
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time: {moment(order?.createDate).format("hh:mm:ss A")}
              </p>
            </div>
            <img
              src={pdfLogo}
              style={{ width: "120px", fontFamily: "Saira, sans-serif" }}
              alt="logo"
            />
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order #: {parseInt(order?.daily_order_id)}
              </p>
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            <div>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order ID:
                <span style={{ textDecoration: "underline" }}>
                  {order && order.slug}
                </span>
              </h3>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                CLIENT:
                <span style={{ textDecoration: "underline", color: "black" }}>
                  {order?.name}
                </span>
              </h3>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 300,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Rx Count: {order?.orderdetail?.length}
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                Delivery Preference:
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "0 5px",
                  textTransform: "uppercase",
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                {order?.parcelPlace}
              </h5>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Delivery Date:
                {moment(order?.deliverAtSelected).format("MM-DD-YYYY")}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time Window: {order?.deliverydate}
              </h4>
              {order?.notes !== "" && (
                <>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontFamily: "Saira, sans-serif",
                        color: "black",
                      }}
                    >
                      Delivery Note:
                    </span>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      padding: "5px 0",
                      fontFamily: "Saira, sans-serif",
                      width: "2.1in",
                      color: "black",
                    }}
                  >
                    {order?.notes}
                  </p>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5px 0",
                color: "black",
              }}
            >
              {order?.totalCopay !== undefined && order?.totalCopay !== 0 && (
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    border: "2px solid black",
                    padding: "0 2px",
                    textAlign: "center",
                    marginBottom: "10px",
                    width: "90px",
                    color: "black",
                  }}
                >
                  COPAY: $ {order?.totalCopay.toFixed(2)}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {order && Object.keys(order).length !== 0 ? (
                  <QRCodeSVG
                    value={order.id.toString()}
                    renderAs="svg"
                    size={80}
                  />
                ) : (
                  <QRCodeSVG renderAs="svg" size={80} value="no Order" />
                )}
                <h5
                  style={{
                    margin: "10px 0",
                    fontSize: "22px",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    fontFamily: "Saira, sans-serif",
                    color: "black",
                  }}
                >
                  {order.city_code ? (
                    <>
                      {order.city_code}
                      {order.zone_code ? ` - ${order.zone_code}` : ""}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontFamily: "Saira, sans-serif",
              color: "black",
            }}
          >
            {(order?.hippaform || order?.lineform || order?.nfaqbform) && (
              <>
                <h4
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    fontWeight: 700,
                    padding: "10px 0",
                    fontFamily: "Saira, sans-serif",
                    color: "black",
                  }}
                >
                  Get Forms Signed:
                </h4>
                <h5
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: "Saira, sans-serif",
                    color: "black",
                  }}
                >
                  {order?.hippaform && <span>Hipaa Form</span>}
                  {order?.lineform && (
                    <span
                      style={{
                        marginLeft: "8px",
                        fontFamily: "Saira, sans-serif",
                        color: "black",
                      }}
                    >
                      Lien Form
                    </span>
                  )}
                  {order?.nfaqbform && (
                    <span
                      style={{
                        marginLeft: "8px",
                        fontFamily: "Saira, sans-serif",
                        color: "black",
                      }}
                    >
                      NF AOB Form
                    </span>
                  )}
                </h5>
              </>
            )}
            <div style={{ textAlign: "center", margin: 0 }}>
              {/* Header text */}
              <h4
                style={{
                  margin: 0,
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                {order?.refrigrated && order?.photoid
                  ? "Refrigerated / Photo ID"
                  : order?.photoid
                  ? "Photo ID"
                  : order?.refrigrated
                  ? "Refrigerated"
                  : ""}
              </h4>

              {/* Icon(s) */}
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {order?.refrigrated && order?.photoid ? (
                  <>
                    <img
                      src={registered}
                      style={{ marginRight: "5px" }}
                      alt="Refrigerated Icon"
                    />
                    <img
                      src={photoId}
                      style={{ marginLeft: "5px" }}
                      alt="Photo ID Icon"
                    />
                  </>
                ) : order?.photoid ? (
                  <img src={photoId} alt="Photo ID Icon" />
                ) : order?.refrigrated ? (
                  <img src={registered} alt="Refrigerated Icon" />
                ) : null}
              </div>
            </div>
            <h5
              style={{
                margin: "0",
                marginTop: "10px",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              From
            </h5>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {order.pharmacy.name}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {order.pharmacy.location}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              Phone {order.pharmacy.contact_no}
            </h4>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        {orderDetail && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "10px",
            }}
            ref={componentRef2}
          >
            <NewPrintDocList
              orderDetail={orderDetail}
              edit={true}
              upload={false}
              view={false}
              pharmacy={pharmacy}
            />
          </div>
        )}
      </div>

      {callModel && (
        <VoiceComponent
          orderCall={callingOrder}
          setSelectedOrder={setCallingOrder}
        />
      )}

      <ConfirmationDialog
        open={pharmacyCallModel}
        title="Initial Call With Pharmacy"
        description={`Please confirm that you want to initiate call with pharmacy ${history?.orderDetail?.pharmacy?.name}?`}
        onConfirm={handlePharmacyMakeCall}
        onCancel={handlePharmacyCallModelClose}
      />

      {/* Reschedule Modal */}
      <Modal open={rescheduleOpen} onClose={handleRescheduleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            p: 3,
            borderRadius: 3,
            boxShadow: 3,
            textAlign: "left",
            background: "linear-gradient(to bottom, #f8fcff, #fff6f6)",
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold" color="#2c64a7">
            Reschedule
          </Typography>

          <TextField
            fullWidth
            type="date"
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
            sx={{
              mt: 1,
              bgcolor: "white",
              borderRadius: "4px",
              height: "36px",
              "& .MuiInputBase-root": {
                height: "36px",
                border: "1px solid #dce0e4",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              },
              "& input": {
                fontSize: "14px",
                color: "#000",
              },
            }}
          />
          <Box sx={{ marginTop: "3%" }}>
            <CustomDropdown
              customstyles={{
                height: "36px",
                width: "full",
              }}
              label="Time Window"
              value={timer}
              onChange={(value) => setTimer(value)}
              options={[
                { value: "Anytime", label: "Anytime" },
                { value: "9am-3pm", label: "9am-3pm" },
                { value: "2pm-6pm", label: "2pm-6pm" },
                { value: "5pm-9pm", label: "5pm-9pm" },
                { value: "Urgent", label: "Urgent" },
              ]}
              isMultiSelect={false}
            />
          </Box>
          <Box
            sx={{ display: "flex", gap: 1, mt: 3, justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setNewDate("");
                setTimer("");
                handleRescheduleClose();
              }}
              sx={{
                textTransform: "none",
                px: 3,
                borderRadius: "8px",
                borderColor: "#D0D5DD",
                color: "#344054",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "#F9FAFB",
                  borderColor: "#D0D5DD",
                },
              }}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              disabled={!newDate || !timer}
              sx={{
                textTransform: "none",
                px: 3,
                borderRadius: "8px",
                borderColor: "#D0D5DD",
                color: "#344054",
                backgroundColor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#F9FAFB",
                  borderColor: "#D0D5DD",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ActionIcons;

// import React, { useState } from "react";
// import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
// import { useTheme } from "@mui/styles";
// import { AttendCallIcon, DialpadIcons, HangUpIcon } from "../Icons";
// import CloseIcon from "@mui/icons-material/Close";
// import VoiceComponent from "../TwilioCalls/TwilioCalls";

// const Dialpad = () => {
//   const theme = useTheme();
//   const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const [initiateCall, setInitiateCall] = useState(false);
//   const [callingOrder, setCallingOrder] = useState({});

//   const userType = localStorage.getItem("userType");

//   const handleOpenCallDialog = () => setIsCallDialogOpen(true);
//   const handleCloseCallDialog = () => {
//     setIsCallDialogOpen(false);
//     setPhoneNumber("")
//   };

//   const formatPhoneNumber = (input) => {
//     const cleaned = input.replace(/\D/g, "");
//     const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
//     if (!match) return input;
//     return [match[1], match[2], match[3]].filter((x) => x).join("-");
//   };

//   const handleDial = (value) => {
//     if (phoneNumber.replace(/\D/g, "").length >= 10) return;
//     setPhoneNumber((prev) => formatPhoneNumber(prev + value));
//   };

//   const handleClear = () => setPhoneNumber("");

//   const handleBackspace = () => {
//     setPhoneNumber((prev) => formatPhoneNumber(prev.slice(0, -1)));
//   };

//   const handleEndCall = () => {
//     setPhoneNumber("");
//     setIsCallDialogOpen(false);
//   };

//   const makeCall = () => {
//     setInitiateCall(true);
//     setIsCallDialogOpen(false);
//     setCallingOrder({
//       number: phoneNumber,
//       name: "Unknown",
//     });
//   };

//   const handleKeyPress = (event) => {
//     const validKeys = [
//       "0",
//       "1",
//       "2",
//       "3",
//       "4",
//       "5",
//       "6",
//       "7",
//       "8",
//       "9",
//       "*",
//       "#",
//     ];
//     if (validKeys.includes(event.key)) {
//       handleDial(event.key);
//     } else if (event.key === "Backspace") {
//       handleBackspace();
//     }
//   };

//   return (
//     <>
//       {(userType === "Admin" || userType === "SubAdmin") &&
//         <Box
//           sx={{ position: "fixed", bottom: "60px", right: "5px", zIndex: 999 }}
//           onKeyDown={handleKeyPress}
//           tabIndex={0}
//         >
//           <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//             <IconButton
//               onClick={handleOpenCallDialog}
//               sx={{
//                 backgroundColor: theme.palette.custom.layoutBgColor,
//                 color: theme.palette.custom.layoutFontColor,
//                 borderRadius: "50px",
//                 padding: "10px 20px",
//                 display: "flex",
//                 alignItems: "center",
//                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//                 "&:hover": {
//                   backgroundColor: theme.palette.custom.layoutHoverColor,
//                 },
//               }}
//             >
//               <DialpadIcons fill={theme.palette.custom.layoutFontColor} />
//             </IconButton>

//             {/* Box Styled like Dialog */}
//             {isCallDialogOpen && (
//               <Box
//                 sx={{
//                   position: "fixed",
//                   bottom: "5px",
//                   right: "5px",
//                   zIndex: 1000,
//                   width: "300px",
//                   borderRadius: "12px",
//                   backgroundColor: "white",
//                   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//                   // padding: "10px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     backgroundColor: theme.palette.custom.layoutBgColor,
//                     color: theme.palette.custom.layoutFontColor,
//                     padding: "8px 12px",
//                     marginBottom: "10px",
//                   }}
//                 >
//                   <Typography sx={{ color: theme.palette.custom.layoutFontColor }}>
//                     Dialer
//                   </Typography>
//                   <IconButton onClick={handleCloseCallDialog}>
//                     <CloseIcon />
//                   </IconButton>
//                 </Box>

//                 <TextField
//                   value={phoneNumber}
//                   fullWidth
//                   autoComplete="off"
//                   placeholder="(000) 111-2222"
//                   className="dalierNumber"
//                   InputProps={{
//                     inputProps: { maxLength: 12 },
//                     style: {
//                       textAlign: "center",
//                     },
//                   }}
//                   sx={{
//                     marginBottom: 2,
//                     color: "black",
//                     marginLeft: "8px",
//                     "& .MuiOutlinedInput-root": {
//                       borderRadius: "8px",
//                       fontSize: "18px",
//                       width: "95%",
//                     },
//                   }}
//                 />

//                 <Box
//                   sx={{
//                     display: "flex",
//                     width: "100%",
//                     flexWrap: "wrap",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     mx: "auto",
//                     gap: 2,
//                   }}
//                 >
//                   {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
//                     <Box key={num}>
//                       <Button
//                         variant="contained"
//                         onClick={() => handleDial(num.toString())}
//                         sx={{
//                           width: "40px",
//                           height: "40px",
//                           borderRadius: "50%",
//                           fontSize: "20px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         {num}
//                       </Button>
//                     </Box>
//                   ))}
//                   <Box>
//                     <Button
//                       variant="contained"
//                       onClick={() => handleDial("*")}
//                       sx={{
//                         width: "40px",
//                         height: "40px",
//                         paddingTop: "15px",
//                         borderRadius: "50%",
//                         fontSize: "20px",
//                       }}
//                     >
//                       *
//                     </Button>
//                   </Box>
//                   <Box>
//                     <Button
//                       variant="contained"
//                       onClick={() => handleDial("0")}
//                       sx={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "50%",
//                         fontSize: "20px",
//                       }}
//                     >
//                       0
//                     </Button>
//                   </Box>
//                   <Box>
//                     <Button
//                       variant="contained"
//                       onClick={() => handleDial("#")}
//                       sx={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "50%",
//                         fontSize: "20px",
//                       }}
//                     >
//                       #
//                     </Button>
//                   </Box>
//                 </Box>

//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     gap: 2,
//                     padding: "16px",
//                   }}
//                 >
//                   <IconButton onClick={makeCall}>
//                     <AttendCallIcon />
//                   </IconButton>
//                 </Box>
//               </Box>
//             )}
//           </Box>
//           {initiateCall && (
//             <VoiceComponent
//               orderCall={callingOrder}
//               setSelectedOrder={setCallingOrder}
//             />
//           )}
//         </Box>
//       }
//     </>
//   );
// };

// export default Dialpad;

import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { AttendCallIcon, DialpadIcons, HangUpIcon } from "../Icons";
import CloseIcon from "@mui/icons-material/Close";
import VoiceComponent from "../TwilioCalls/TwilioCalls";

const Dialpad = () => {
  const theme = useTheme();
  const iconRef = useRef(null);
  const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [initiateCall, setInitiateCall] = useState(false);
  const [callingOrder, setCallingOrder] = useState({});

  const userType = localStorage.getItem("userType");

  // const handleOpenCallDialog = () => setIsCallDialogOpen(true);
  // const handleCloseCallDialog = () => {
  //   setIsCallDialogOpen(false);
  //   setPhoneNumber("");
  // };

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return input;

    let formattedNumber = "";
    if (match[1]) formattedNumber += `(${match[1]}`;
    if (match[2]) formattedNumber += `) ${match[2]}`;
    if (match[3]) formattedNumber += `-${match[3]}`;

    return formattedNumber;
  };

  const handleDial = (value) => {
    if (phoneNumber.replace(/\D/g, "").length >= 10) return;
    setPhoneNumber((prev) => formatPhoneNumber(prev + value));
  };

  const handleClear = () => setPhoneNumber("");

  const handleBackspace = () => {
    setPhoneNumber((prev) => formatPhoneNumber(prev.slice(0, -1)));
  };

  const handleEndCall = () => {
    setPhoneNumber("");
    setIsCallDialogOpen(false);
  };

  const makeCall = () => {
    setInitiateCall(true);
    setIsCallDialogOpen(false);
    setCallingOrder({
      number: phoneNumber,
      name: "Unknown",
    });
  };

  const handleKeyPress = (event) => {
    const validKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "*",
      "#",
    ];
    if (validKeys.includes(event.key)) {
      handleDial(event.key);
    } else if (event.key === "Backspace") {
      handleBackspace();
    }
  };

  // Muhammad Usman
  const [position, setPosition] = useState({
    x: window.innerWidth - 320,
    y: window.innerHeight - 400,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, offset]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  // Independent Position States
  /** ✅ Button State (Bottom-Right Initially) */
  const [buttonPosition, setButtonPosition] = useState({
    x: window.innerWidth - 80, // Right side
    y: window.innerHeight - 80, // Bottom side
  });

  /** ✅ Dialpad State (Initially Hidden) */
  const initialPosition = {
    x: window.innerWidth - 320, // Right side
    y: window.innerHeight - 515, // A bit above the bottom
  };

  const [dialpadPosition, setDialpadPosition] = useState(initialPosition);

  // Draggable states for both elements
  const [isDraggingButton, setIsDraggingButton] = useState(false);
  const [isDraggingDialpad, setIsDraggingDialpad] = useState(false);

  // Move Function
  const handleMouseMove = (e) => {
    if (isDraggingButton) {
      setButtonPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    }
    if (isDraggingDialpad) {
      setDialpadPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    }
  };

  const handleMouseUp = () => {
    setIsDraggingButton(false);
    setIsDraggingDialpad(false);
  };

  useEffect(() => {
    if (isDraggingButton || isDraggingDialpad) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDraggingButton, isDraggingDialpad]);

  const handleMouseDownButton = (e) => {
    setIsDraggingButton(true);
    setOffset({
      x: e.clientX - buttonPosition.x,
      y: e.clientY - buttonPosition.y,
    });
  };

  const handleMouseDownDialpad = (e) => {
    setIsDraggingDialpad(true);
    setOffset({
      x: e.clientX - dialpadPosition.x,
      y: e.clientY - dialpadPosition.y,
    });
  };

  // Call Handlers
  const handleOpenCallDialog = () => setIsCallDialogOpen(true);
  const handleCloseCallDialog = () => {
    setIsCallDialogOpen(false);
    setDialpadPosition(initialPosition); // Reset to initial position
  };

  return (
    <>
      {(userType === "Admin" || userType === "SubAdmin") && (
        <Box
          sx={{ position: "fixed", bottom: "60px", right: "5px", zIndex: 999 }}
          onKeyDown={handleKeyPress}
          tabIndex={0}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton
              onClick={handleOpenCallDialog}
              onMouseDown={handleMouseDownButton}
              sx={(theme) => ({
                position: "fixed",
                left: `${buttonPosition.x}px`,
                top: `${buttonPosition.y}px`,
                backgroundColor:
                  theme.palette.custom.layoutBgColor === "#E6E6E6"
                    ? "#E6E6E6"
                    : "#091235",
                "&:hover": {
                  backgroundColor:
                    theme.palette.custom.layoutBgColor ===
                    theme.palette.custom.layoutHoverColor
                      ? "darkred"
                      : "#2B4257",
                },
                color: "#000",
                borderRadius: "50px",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                cursor: "grab",
                zIndex: 9999,
              })}
            >
              <DialpadIcons fill={theme.palette.custom.layoutFontColor} />
            </IconButton>

            {isCallDialogOpen && (
              <Box
                sx={{
                  position: "fixed",
                  left: `${dialpadPosition.x}px`,
                  top: `${dialpadPosition.y}px`,
                  zIndex: 1000,
                  width: "300px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: theme.palette.custom.layoutBgColor,
                    color: theme.palette.custom.layoutFontColor,
                    padding: "8px 12px",
                    marginBottom: "10px",
                    cursor: "grab",
                    userSelect: "none",
                  }}
                  onMouseDown={handleMouseDownDialpad}
                >
                  <Typography
                    sx={{ color: theme.palette.custom.layoutFontColor }}
                  >
                    Dialer
                  </Typography>
                  <IconButton onClick={handleCloseCallDialog}>
                    <CloseIcon
                      sx={{ color: theme.palette.custom.layoutFontColor }}
                    />
                  </IconButton>
                </Box>

                <TextField
                  value={phoneNumber}
                  fullWidth
                  autoComplete="off"
                  placeholder="(000) 111-2222"
                  className="dalierNumber"
                  InputProps={{
                    inputProps: { maxLength: 12 },
                    style: {
                      textAlign: "center",
                    },
                  }}
                  sx={{
                    marginBottom: 2,
                    color: "black",
                    marginLeft: "8px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      fontSize: "18px",
                      width: "95%",
                    },
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: "auto",
                    gap: 2,
                  }}
                >
                  {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                    <Box key={num}>
                      <Button
                        variant="contained"
                        onClick={() => handleDial(num.toString())}
                        sx={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%", 
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "black", 
                          color: "white", 
                        }}
                      >
                        {num}
                      </Button>
                    </Box>
                  ))}
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("*")}
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%", 
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "black", 
                        color: "white", 
                        paddingTop: "12px"
                      }}
                    >
                      *
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("0")}
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%", 
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "black", 
                        color: "white", 
                      }}
                    >
                      0
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("#")}
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%", 
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "black", 
                        color: "white", 
                      }}
                    >
                      #
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    padding: "16px",
                  }}
                >
                  <IconButton onClick={makeCall}>
                    <AttendCallIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
          {initiateCall && (
            <VoiceComponent
              orderCall={callingOrder}
              setSelectedOrder={setCallingOrder}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Dialpad;

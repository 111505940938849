import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../components/ticketPageCompo/Topbar";
import TicketStatus from "../components/ticketPageCompo/TicketStatus";
import ChatWindow from "../components/createOrderPageCompo/tabButtonsCompo/ChatWindow";
import { getApiWithAuth } from "../utils/api";
import { CREATE_TICKET, ALL_PHARMACY_LIST } from "../utils/apiUrl";
import moment from "moment";
const TicketsPage = () => {

  const [loading, setLoading] = useState(false);
  const [ticketOrders, setTicketOrders] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [ticketDetail, setTicketDetail] = useState();
  const [search, setSearch] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [boroughs, setBoroughs] = useState([]);
  const [pharmacy, setPharmacy] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);


  const { tid } = useParams();


  const handleTicketClick = (ticketId) => {
    setSelectedTicketId(ticketId);
  };

  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {
        justList: true,
      });
      if (response.data !== undefined) {
        setUniquePharmacy(
          response.data.data.map((item) => ({ id: item.id, name: item.name }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setUniquePharmacy([]);
    }
  };

  const handleBoroughChange = (newBoroughs) => {
    setBoroughs(newBoroughs);
  };
  const handleSearchChange = (newBoroughs) => {
    setSearch(newBoroughs);
  };
  const handlePharmacyChange = (newPharmacy) => {
    setPharmacy(newPharmacy);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const getTicketOrders = async () => {
    setLoading(true);

    let apiUrl = `${CREATE_TICKET}?data=all`;

    if (pharmacy && pharmacy !== "") {
      apiUrl += `&pharmacy=${pharmacy}`;
    }

    if (search) {
      apiUrl += `&search=${search}`;
    }

    if (tid === undefined && tid === null) {

      apiUrl += `&from=${moment(dateRange[0]).format("MM-DD-YYYY")}`;
      apiUrl += `&to=${moment(dateRange[1]).format("MM-DD-YYYY")}`;
    }


    if (boroughs === "Pending") {
      apiUrl += "&pending=true";
    } else if (boroughs === "Completed") {
      apiUrl += "&completed=true";
    }

    try {
      const response = await getApiWithAuth(apiUrl);
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setTicketOrders(response.data.data);
        if (tid !== undefined && tid !== null) {
          setSelectedTicketId(parseInt(tid));
          setTicketId(tid);
          getTicketDetail(tid);
        }
        else if (ticketId === "" && response.data.data[0].id) {
          setSelectedTicketId(response.data.data[0].id);
          setTicketId(response.data.data[0].id);
          getTicketDetail(response.data.data[0].id);
        }
      } else {
        setTicketOrders([]);
      }
    } catch (error) {
      console.error("Error fetching ticket orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTicketDetail = async (id) => {
    setLoading(true);
    console.log("idin ticket details", id);
    if (id) {
      const response = await getApiWithAuth(CREATE_TICKET + `${id}/`);
      if (response.data !== undefined) {
        setTicketId(id);
        setTicketDetail(response.data.data);
        setLoading(false);
      } else {
        setTicketId(id);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getTicketOrders();
  }, [pharmacy, search, boroughs, dateRange]);

  useEffect(() => {
    getTicketDetail(selectedTicketId);
  }, [selectedTicketId]);

  useEffect(() => {
    fetchPharmacies();
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Topbar
        boroughs={boroughs}
        setBoroughs={setBoroughs}
        pharmacy={pharmacy}
        pharmacyList={uniquePharmacy || []}
        setPharmacy={setPharmacy}
        dateRange={dateRange}
        setDateRange={handleDateRangeChange}
        setSearch={handleSearchChange}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {ticketOrders?.map((ticket, index) => (
            <TicketStatus
              key={ticket.id || index}
              ticket={ticket}
              onchangedticket={getTicketOrders}
              onTicketClick={handleTicketClick}
              isActive={selectedTicketId === ticket.id}
            />
          ))}
        </Box>
        {ticketDetail && (
          <Box sx={{ flex: 3, marginLeft: 2 }}>
            <ChatWindow
              ticketDetail={ticketDetail}
              getTicketDetail={getTicketDetail}
              getTicketOrders={getTicketOrders}
              ticketId={selectedTicketId}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TicketsPage;
